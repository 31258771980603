import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { isStringEmpty, usePickPropsIfNotNull } from "../../../declarations/common_utils";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import { IFetchConfig, IPostFileDataRepr } from "./ModalUpload";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import { AxiosResponse } from "axios";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";

interface IFileConfigRepr {
    display: string[] | number[],
    values: number[]
}

interface IFileFileCatConfigRepr {
    [key: string]: IFileConfigRepr
}

interface IFileInfo extends AxiosResponse {
    data: {
        f_bns_id: number,
        f_cat_id: number,
        f_year: number,
        f_name: string,
        f_file_cat_id: number,
        f_dep: number,
        f_access: number
    }
}

interface IFileTransfer extends AxiosResponse {
    data: {
        data: IDataRepr,
        prev_d_file: IPostFileDataRepr,
        d_file: IPostFileDataRepr
    }
}

export interface IModalTransferFileProps {
    userId: number,
    fileId: number,
    bnsShow: string[],
    bnsValues: number[],
    mutator: (file: IDataRepr, prevFileData: IPostFileDataRepr, currFileData: IPostFileDataRepr) => void,
    onClose: () => void
}

function ModalTransferFile(props: IModalTransferFileProps) {
    const ENDPOINT = "api/clientdashboard/";

    //Form Field Values
    const [fileAccess, setFileAccess] = React.useState(-1);
    const [fileBnsId, setFileBnsId] = React.useState(-1);
    const [fileCatId, setFileCatId] = React.useState(-1);
    const [fileYear, setFileYear] = React.useState(-1);
    const [fileName, setFileName] = React.useState("");
    const [fileDep, setFileDep] = React.useState(-1);
    const [fileFileCatId, setFileFileCatId] = React.useState(-1);

    //Config
    const [fileAccessConfig, setFileAccessConfig] = React.useState<IFileConfigRepr>();
    const [fileCatConfig, setFileCatConfig] = React.useState<IFileConfigRepr>();
    const [fileYearConfig, setFileYearConfig] = React.useState<IFileConfigRepr>();
    const [fileDepConfig, setFileDepConfig] = React.useState<IFileConfigRepr>();
    const [fileFileCatConfig, setFileFileCatConfig] = React.useState<IFileFileCatConfigRepr>();

    const [inTransit, setIsTransit] = React.useState(false);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.fileId != -1) {
            let queryParams = {
                section: "upload_config",
                user_id: props.userId
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchConfig>
            requestResponse.then((response) => {
                setFileAccessConfig({ display: response.data.access_options, values: response.data.access_values });
                setFileCatConfig({
                    display: ["Personal", "Business"],
                    values: [response.data.cat_config.uploadpersonal, response.data.cat_config.uploadbusiness]
                });
                setFileYearConfig({ display: response.data.years, values: response.data.years });
                setFileDepConfig({ display: response.data.dep_options, values: response.data.dep_values });
                let perFileTypeShow: string[] = ["Select File Type"];
                let perFileTypeValues: number[] = [0];
                let bnsFileTypeShow: string[] = ["Select File Type"];
                let bnsFileTypeValues: number[] = [0];
                response.data.per_file_types.forEach((ele) => {
                    perFileTypeShow.push(ele.file_type_name);
                    perFileTypeValues.push(ele.id);
                })
                response.data.bns_file_types.forEach((ele) => {
                    bnsFileTypeShow.push(ele.file_type_name);
                    bnsFileTypeValues.push(ele.id);
                })
                let personalId = response.data.cat_config.uploadpersonal.toString();
                let businessId = response.data.cat_config.uploadbusiness.toString();
                let fileFileCatConfig: IFileFileCatConfigRepr = {}
                fileFileCatConfig[personalId] = {display: perFileTypeShow, values: perFileTypeValues};
                fileFileCatConfig[businessId] = {display: bnsFileTypeShow, values: bnsFileTypeValues};
                setFileFileCatConfig(fileFileCatConfig);

                let queryParams = {
                    section: "documents_file_info",
                    user_id: props.userId,
                    file_id: props.fileId
                }
                const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFileInfo>;
                requestResponse.then((response) => {
                    setFileAccess(response.data.f_access);
                    setFileBnsId(response.data.f_bns_id);
                    setFileCatId(response.data.f_cat_id);
                    setFileYear(response.data.f_year);
                    setFileName(response.data.f_name);
                    setFileDep(response.data.f_dep);
                    setFileFileCatId(response.data.f_file_cat_id);
                })
            })
        }
    }, [props.fileId])

    React.useEffect(() => {
        $("#fileTransferModal").on("hidden.bs.modal", closeForm);
        return () => {
            cancelTokenSource.current.cancel();
            $("#fileTransferModal").off("hidden.bs.modal");
        }
    }, [])

    function closeForm() {
        setFileAccess(-1);
        setFileBnsId(-1);
        setFileCatId(-1);
        setFileYear (-1);
        setFileName("");
        setFileDep(-1);
        setFileFileCatId(-1);

        setFileAccessConfig(undefined);
        setFileCatConfig(undefined);
        setFileYearConfig(undefined);
        setFileDepConfig(undefined);
        setFileFileCatConfig(undefined);

        setIsTransit(false);
        props.onClose();
    }

    function isDataFetched() {
        return (
            (fileAccess != -1) && (fileBnsId != -1) && (fileCatId != -1) &&
            (fileYear != -1) && (!isStringEmpty(fileName)) && (fileDep != -1) &&
            (fileFileCatId != -1)
        )
    }

    function formChange(field: "fileCatId" | "fileYear" | "fileAccess" | "fileFileCatConfig" | "fileDep" | "fileBnsId" | "fileName", e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) {
        if (field == "fileCatId") {
            setFileCatId(parseInt(e.target.value));
        } else if (field == "fileYear") {
            setFileYear(parseInt(e.target.value));
        } else if (field == "fileAccess") {
            setFileAccess(parseInt(e.target.value));
        } else if (field == "fileFileCatConfig") {
            setFileFileCatId(parseInt(e.target.value));
        } else if (field == "fileDep") {
            setFileDep(parseInt(e.target.value));
        } else if (field == "fileBnsId") {
            setFileBnsId(parseInt(e.target.value));
        } else if (field == "fileName") {
            setFileName(e.target.value);
        }
    }

    function getContent() {
        if (isDataFetched() && fileCatConfig != undefined && fileYearConfig != undefined && fileAccessConfig != undefined &&
            fileFileCatConfig != undefined && fileDepConfig != undefined) {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ModalInputText title="File Name" value={fileName} onChange={(e) => formChange("fileName", e)} required />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <ModalDropDown options={fileCatConfig.display} values={fileCatConfig.values} 
                                value={fileCatId} onChange={(e) => formChange("fileCatId", e)} required />
                        </div>
                    </div>
                    {(() => {
                        if (fileCatId == fileCatConfig.values[1]) {
                            return (
                                <div className="row mt-3">
                                    <div className="col-12 mt-3">
                                        <ModalDropDown options={props.bnsShow} values={props.bnsValues}
                                            value={fileBnsId} onChange={(e) => formChange("fileBnsId", e)} required />
                                    </div>
                                </div>
                            )
                        }
                    })()}
                    <div className="row mt-3">
                        <div className="col-6">
                            <ModalDropDown options={fileYearConfig.display} values={fileYearConfig.values}
                                value={fileYear} onChange={(e) => formChange("fileYear", e)} required />
                        </div>
                        <div className="col-6">
                            <ModalDropDown options={fileAccessConfig.display} values={fileAccessConfig.values}
                                value={fileAccess} onChange={(e) => formChange("fileAccess", e)} required />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <ModalDropDown options={fileFileCatConfig[fileCatId].display}  values={fileFileCatConfig[fileCatId].values}
                                value={fileFileCatId} onChange={(e) => formChange("fileFileCatConfig", e)} required />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <ModalDropDown options={fileDepConfig.display} values={fileDepConfig.values}
                                value={fileDep} onChange={(e) => formChange("fileDep", e)} required />
                        </div>
                    </div>
                </div>
            )
        }
    }

    function saveCallback() {
        let queryParams = {
            section: "documents_transfer",
            user_id: props.userId,
            file_id: props.fileId
        }
        let formData = new FormData();
        formData.append("f_bns_id", fileBnsId.toString());
        formData.append("f_cat_id", fileCatId.toString());
        formData.append("f_year", fileYear.toString());
        formData.append("f_file_name", fileName);
        formData.append("f_file_cat_id", fileFileCatId.toString());
        formData.append("f_dep", fileDep.toString());
        formData.append("f_access", fileAccess.toString());
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IFileTransfer>;
        requestResponse.then((response) => {
            props.mutator(response.data.data, response.data.prev_d_file, response.data.d_file);
        }).finally(() => {
            $("#fileTransferModal").modal("hide");
        })
        setIsTransit(true);
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal custom-small text-center" id="fileTransferModal"
                tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Transfer Document"} />
                        <div className="modal-body" style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                            {getContent()}
                        </div>
                        <ModalFooter saveCallback={saveCallback} saveInactive={inTransit} />
                    </div>
                </div>
            </div>
        )
    }
    
    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalTransferFile;