import React from "react";
import { getCancelToken, post } from "../../../../../intelws_portal/utils/backendInterface";
import { IRedirectResponse } from "./Signin";

function Logout() {
    const ENDPOINT = "/api/authentication/";

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "logout"
        }
        let formData = new FormData();
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IRedirectResponse>;
        requestResponse.then((response) => {
            if (typeof response.data.path == "string") {
                window.location.pathname = response.data.path;
            } else {
                window.location.hash = response.data.path[1];
                window.location.pathname = response.data.path[0];
            }
        })
    }, [])

    return (
        <p>Signing Out</p>
    )
}

export default Logout;