import React, { Component } from "react";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";

import ContextBoxMultiTabTable, { IContentBoxMultiTabTableTabMiscButtonRepr, IContentBoxMultiTabTableTabMiscDropdownRepr, IContentBoxMultiTabTableTabRepr }
from '../../../intelws_portal/bundles/ContentBoxMultiTabTable';
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import Checkbox from '../../../intelws_portal/constructs/elements/Checkbox';
import ScrollTop from "../../../intelws_portal/constructs/elements/ScrollTop";
import { ICheckboxRepr, IDataRepr, IHtmlComponentRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import updateCache from "../../../intelws_portal/utils/updateCache";

import * as urls from '../../Urls';
import { IPageComponentProps } from "../../../declarations/common";


interface IFetchTabConfigRepr {
    buttons: {
        [key: string]: IHtmlComponentRepr[] | undefined
    },
    tab_config: {
        tab_name: string,
        is_show_button: boolean,
        status?: {
            status_name: string
        }[]
    }[],
    years: number[]
}

interface IFetchTabConfig extends AxiosResponse {
    data : IFetchTabConfigRepr
}

interface IFetchRecords extends AxiosResponse {
    data: {
        data: IDataRepr[],
        has_checkbox: boolean
    } 
}

interface IPostTaskListAction extends AxiosResponse {
    data: {
        bucket_to_move: string,
        status_to_change_obj: {
            ut_current_status_name: string
        }
    }
}

interface IButtonConfig {
    [key: string]: (IContentBoxMultiTabTableTabMiscButtonRepr | IContentBoxMultiTabTableTabMiscDropdownRepr)[] | undefined
}

function TaskList(props: IPageComponentProps) {
    const ENDPOINT = "api/tasklist/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tabConfig, setTabConfig] = React.useState<IFetchTabConfigRepr | undefined>(undefined);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[][]>([]);
    const [tableCheckedId, setTableCheckedId] = React.useState<number[]>([]);
    const [empDropdownValue, setEmpDropdownValue] = React.useState(-1);
    const [topLevelIndex, setTopLevelIndex] = React.useState(0);
    const [secondLevelIndex, setSecondLevelIndex] = React.useState(0);
    const [currYear, setCurrYear] = React.useState<number | string>("");
    const [currStatus, setCurrStatus] = React.useState<string[]>([])
    const [years, setYears] = React.useState<(number | string)[]>([]);
    const [showCheckbox, setShowCheckbox] = React.useState<boolean[]>([]);
    const [selectAllChecked, setSelectAllChecked] = React.useState(false);
    const cancelTokenSource = React.useRef(getCancelToken());
    const yearAllText = React.useRef("All Tax Year");
    const statusAllText = React.useRef("All Status");
    
    const displayTableData = React.useMemo(filter, [tableData, currYear, currStatus]);
    
    
    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("Task")
        }
        fetchConfig((tabConfig) => {
            fetchRecords(tabConfig.tab_config[0].tab_name.toLowerCase(), "personal", (data, hasCheckbox) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    tabConfig.tab_config.forEach((ele) => {
                        newTableData.push(new Array(2).fill(undefined));
                    })
                    newTableData[0][0] = [...data];
                    return newTableData;
                })
                setShowCheckbox((prevShowCheckbox) => {
                    let newShowCheckbox = [...prevShowCheckbox];
                    newShowCheckbox = new Array(tabConfig.tab_config.length).fill(false);
                    newShowCheckbox[0] = hasCheckbox;
                    return newShowCheckbox;
                })
                setTabConfig(tabConfig);
                let years: (number | string)[] = tabConfig.years;
                years.unshift(yearAllText.current);
                setYears(years);
                setCurrYear(years[1]);
                let currStatusSelected: string[] = [];
                tabConfig.tab_config.forEach((ele) => {
                    if (ele.status != undefined) {
                        if (ele.status.length == 1) {
                            currStatusSelected.push(ele.status[0].status_name)
                        } else {
                            currStatusSelected.push(statusAllText.current);
                        }
                    } else {
                        currStatusSelected.push(statusAllText.current);
                    }
                })
                setCurrStatus(currStatusSelected);
                setIsLoaded(true);
            })
        })
    }, [])

    function filter() {
        let returnTableData = cloneDeep(tableData);
        returnTableData = returnTableData.map((ele, topLevelIndex) => {
            return ele.map((childEle) => {
                if (childEle == undefined) {
                    return undefined;
                } else {
                    if (currYear != yearAllText.current && typeof currYear == "number") {
                        childEle = childEle.filter((tableEle) => {
                            return tableEle["ut_year"] == currYear;
                        })
                    }
                    if (currStatus[topLevelIndex] != statusAllText.current) {
                        childEle = childEle.filter((tableEle) => {
                            return tableEle["ut_current_status_name_string"] == currStatus[topLevelIndex];
                        })
                    }
                    childEle = childEle.map((tableEle) => {
                        if (currStatus[topLevelIndex] == statusAllText.current) {
                            (tableEle["selectCheckbox"] as ICheckboxRepr).disabled = true;
                        } else {
                            (tableEle["selectCheckbox"] as ICheckboxRepr).disabled = false;
                        }
                        return tableEle;
                    })
                    return childEle;
                }
            })
        })
        return returnTableData;
    }

    function clearCheckbox(topLevelIndex: number, secondLevelIndex: number, clearSelectedId?: boolean, clearTableUI?: boolean) {
            if ((clearSelectedId == undefined) || (typeof clearSelectedId == "boolean" && clearSelectedId)) {
                setTableCheckedId([]);
            }
            if (((clearTableUI == undefined) || (typeof clearTableUI == "boolean" && clearTableUI)) && (showCheckbox[topLevelIndex])) {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    let currTable = newTableData[topLevelIndex][secondLevelIndex];
                    if (currTable != undefined) {
                        currTable.forEach((ele) => {
                            (ele.selectCheckbox as ICheckboxRepr).checked = false;
                        })
                    }
                    return newTableData;
                })
                setSelectAllChecked(false);
            }
    }

    function fetchConfig(successCallback: (configData: IFetchTabConfigRepr) => void) {
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, { section: "config" }) as Promise<IFetchTabConfig>;
        requestResponse.then((response) => {
            successCallback(response.data);
        })
    }

    function fetchRecords(statusBucket: string, cat: string, successCallback: (data: IDataRepr[], hasCheckbox: boolean) => void) {
        let queryParams = {
            section: "task_list",
            status_bucket: statusBucket,
            cat: cat
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchRecords>;
        requestResponse.then((response) => {
            successCallback(response.data.data, response.data.has_checkbox);
        })
    }

    function secondLevelEleCallback(selectedButton: string) {
        let formData = new FormData();
        formData.append("action", selectedButton);
        formData.append("tracking_ids", JSON.stringify(tableCheckedId));
        if (selectedButton == "Assign") {
            formData.append("emp", empDropdownValue.toString());
        }
        clearCheckbox(topLevelIndex, secondLevelIndex, false);
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, { section: "task_list" }) as Promise<IPostTaskListAction>;
        requestResponse.then((response) => {
            if (selectedButton != "Send Reminder") {
                if (tabConfig != undefined) {
                    setTableData((prevTableData) => {
                        let newTableData = [...prevTableData];
                        let currBucket = tabConfig.tab_config[topLevelIndex].tab_name;
                        let moveBucketIndex = tabConfig.tab_config.findIndex((ele) => {
                            return ele.tab_name == response.data.bucket_to_move;
                        });
                        let recordsMoveFrom = newTableData[topLevelIndex][secondLevelIndex];
                        let overviewRecords = newTableData[newTableData.length - 1][secondLevelIndex];
                        let recordsMoveTo: IDataRepr[] | undefined = [];
                        let recordsToMove: IDataRepr[] = [];
                        let recordsMoveIndex = -1;
                        if (recordsMoveFrom != undefined) {
                            recordsMoveFrom.forEach((ele) => {
                                if (tableCheckedId.includes(ele.id)) {
                                    for (let [key, value] of Object.entries(response.data.status_to_change_obj)) {
                                        ele[key] = value;
                                    }
                                    recordsToMove.push(ele);
                                    if (overviewRecords != undefined) {
                                        let recordUpdate = overviewRecords.find((overviewEle) => {
                                            return ele.id == overviewEle.id;
                                        })
                                        if (recordUpdate != undefined) {
                                            for (let [key, value] of Object.entries(response.data.status_to_change_obj)) {
                                                recordUpdate[key] = value;
                                            }
                                        }
                                    }
                                }
                            })
                        }
                        if (moveBucketIndex > -1 && currBucket != tabConfig.tab_config[moveBucketIndex].tab_name) {
                            recordsMoveTo = newTableData[moveBucketIndex][secondLevelIndex];
                            recordsMoveIndex = moveBucketIndex;
                        }
                        if (recordsMoveIndex > -1) {
                            let updatedRecords = updateCache([recordsMoveFrom, recordsMoveTo], 0, 1, recordsToMove);
                            newTableData[topLevelIndex][secondLevelIndex] = updatedRecords[0];
                            newTableData[moveBucketIndex][secondLevelIndex] = updatedRecords[1];
                        }
                        return newTableData;
                    })
                }
            }
        })
        clearCheckbox(topLevelIndex, secondLevelIndex, true, false);
    }

    function secondLevelDropdownCallback(selectedEmpId: string) {
        setEmpDropdownValue(parseInt(selectedEmpId));
    }

    function selectAllCheckboxCallback(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectAllChecked(e.target.checked);
        let relevantDisplayData = displayTableData[topLevelIndex][secondLevelIndex];
        let relevantIds: number[] = [];
        if (relevantDisplayData != undefined) {
            relevantIds = relevantDisplayData.map((ele) => {
                return ele.id;
            })
        }
        setTableCheckedId(relevantIds);
        setTableData((prevTableData) => {
            let newTableData = [...prevTableData];
            let relevantTableData = newTableData[topLevelIndex][secondLevelIndex];
            if (relevantDisplayData != undefined && relevantTableData != undefined) {
                relevantTableData.forEach((ele) => {
                    if (relevantIds.includes(ele.id)) {
                        (ele["selectCheckbox"] as ICheckboxRepr).checked = e.target.checked;
                    }
                })
            }
            return newTableData;
        })
    }

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "boolean" && accessKey == "selectCheckbox") {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let relevantTableData = newTableData[topLevelIndex][secondLevelIndex];
                let relevantDisplayData = displayTableData[topLevelIndex][secondLevelIndex];
                let absoluteRowIndex = -1;
                if (relevantDisplayData != undefined && relevantTableData != undefined) {
                    let recordId = relevantDisplayData[rowIndex].id;
                    absoluteRowIndex = relevantTableData.findIndex((ele) => {
                        return ele.id == recordId;
                    })
                }
                if (relevantTableData != undefined) {
                    (relevantTableData[absoluteRowIndex].selectCheckbox as ICheckboxRepr).checked = value;
                    setTableCheckedId((prevTableCheckedId) => {
                        let newTableCheckedId = [...prevTableCheckedId];
                        if (relevantTableData != undefined) {
                            if (value) {
                                newTableCheckedId.push(relevantTableData[absoluteRowIndex].id);
                            } else {
                                newTableCheckedId.splice(newTableCheckedId.findIndex((ele) => {
                                    if (relevantTableData != undefined) {
                                        return relevantTableData[absoluteRowIndex].id == ele;
                                    }
                                }), 1);
                            }
                        }
                        return newTableCheckedId;
                    })
                }
                return newTableData;
            })
        } else if (typeof value == "boolean") {
            let relevantTableData = tableData[topLevelIndex][secondLevelIndex];
            let relevantDisplayData = displayTableData[topLevelIndex][secondLevelIndex];
            let absoluteRowIndex = -1;
            if (relevantDisplayData != undefined && relevantTableData != undefined) {
                let recordId = relevantDisplayData[rowIndex].id;
                absoluteRowIndex = relevantTableData.findIndex((ele) => {
                    return ele.id == recordId;
                })
            }
            let formData = new FormData();
            if (relevantTableData != undefined) {
                formData.append("tracking_id", relevantTableData[absoluteRowIndex].id.toString());
                formData.append("access_key", accessKey);
                formData.append("value", value.toString());
            }
            let queryParams = { section: "set_flag" };
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    let relevantTableData = newTableData[topLevelIndex][secondLevelIndex];
                    if (relevantTableData != undefined) {
                        (relevantTableData[absoluteRowIndex][accessKey] as ICheckboxRepr).checked = value;
                    }
                    return newTableData;
                })
            })
        }
    }

    function statusDropdownCallback(status_name: string) {
        setCurrStatus((prevCurrStatus) => {
            let newCurrStatus = [...prevCurrStatus];
            newCurrStatus[topLevelIndex] = status_name;
            return newCurrStatus;
        })
        clearCheckbox(topLevelIndex, secondLevelIndex);
    }

    function yearDropdownCallback(year: string) {
        if (year == yearAllText.current) {
            setCurrYear(year);
        } else {
            setCurrYear(parseInt(year));
        }
        clearCheckbox(topLevelIndex, secondLevelIndex);
    }

    function tabCallback(prevTopLevelIndex: number, prevSecondLevelIndex: number, topLevelIndex: number, secondLevelIndex: number, contentName: string) {
        setTopLevelIndex(topLevelIndex);
        setSecondLevelIndex(secondLevelIndex);
        clearCheckbox(prevTopLevelIndex, prevSecondLevelIndex);
        if (tableData[topLevelIndex][secondLevelIndex] == undefined) {
            let splitContentName = contentName.split("_");
            fetchRecords(splitContentName[0], splitContentName[1], (data, hasChecbox) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[topLevelIndex][secondLevelIndex] = [...data];
                    return newTableData;
                })
                setShowCheckbox((prevShowCheckbox) => {
                    let newShowCheckbox = [...prevShowCheckbox];
                    newShowCheckbox[topLevelIndex] = hasChecbox;
                    return newShowCheckbox;
                })
            })
        }
    }

    function getContent() {
        let perColumnNames = [
            <Checkbox checked={selectAllChecked} disabled={currStatus[topLevelIndex] == statusAllText.current} onChange={selectAllCheckboxCallback} />,
            "Client", "Tax Year", "Status", "Due On", "Team Member", "When Uploaded", "Engagement", "Upload"];
        let perAccessKeys = [
            'selectCheckbox', 'ut_user_name', 'ut_year', 'ut_current_status_name', 'ut_due_date',
            'ut_emp_assigned_name', 'ut_last_uploaded_time', 'ut_eg_status', 'ut_upload_status' ];
        let businessColumnNames = [
            <Checkbox checked={selectAllChecked} disabled={currStatus[topLevelIndex] == statusAllText.current} onChange={selectAllCheckboxCallback} />,
            "Client", "Business", "Tax Year", "Status", "Due On", "Team Member", "When Uploaded", "Engagement", "Upload"];
        let businessAccessKeys = [
            'selectCheckbox', 'ut_user_name', 'ut_bns_name', 'ut_year', 'ut_current_status_name', 'ut_due_date',
            'ut_emp_assigned_name', 'ut_last_uploaded_time', 'ut_eg_status', 'ut_upload_status'];
        let noCheckboxPerColumnNames = [
            "Client", "Tax Year", "Status", "Due On", "Team Member", "When Uploaded", "Engagement", "Upload"];
        let noCheckboxPerAccessKeys = [
            'ut_user_name', 'ut_year', 'ut_current_status_name', 'ut_due_date', 'ut_emp_assigned_name', 'ut_last_uploaded_time', 'ut_eg_status', 'ut_upload_status'];
        let noCheckboxBusinessColumnNames = [
            "Client", "Business", "Tax Year", "Status", "Due On", "Team Member", "When Uploaded", "Engagement", "Upload"];
        let noCheckboxBusinessAccessKeys = [
            'ut_user_name', 'ut_bns_name', 'ut_year', 'ut_current_status_name', 'ut_due_date',
            'ut_emp_assigned_name', 'ut_last_uploaded_time', 'ut_eg_status', 'ut_upload_status'];
        let perlink = { ut_user_name: { baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD, urlParams: ['ut_user_id'] } };
        let bnslink = { ut_user_name: { baseUrl: urls.BASE_COMMON_CLIENT_DASHBOARD, urlParams: ['ut_user_id'] } };
        let tabs: IContentBoxMultiTabTableTabRepr[] = [];
        let childrenTab = [{ id: "personal", active: true, display: "Individual", target: "Personal" },
            { id: "business", active: false, display: "Business", target: "Business" }]
        let buttonConfig: IButtonConfig = cloneDeep(tabConfig?.buttons as IButtonConfig);
        for (let [key, value] of Object.entries(buttonConfig)) {
            if (value != undefined) {
                value.forEach((ele) => {
                    if (ele.type != "dropdown") {
                        ele.callback = secondLevelEleCallback;
                    } else {
                        ele.callback = secondLevelDropdownCallback;
                        ele.value = empDropdownValue
                    }
                })
            }
        }
        if (tabConfig != undefined) {
            tabConfig.tab_config.forEach((ele, index) => {
                let currPerColumnNames = perColumnNames;
                let currBusinessColumnNames = businessColumnNames;
                let currPerAccessKeys = perAccessKeys;
                let currBusinessAccessKeys = businessAccessKeys;
                if (!showCheckbox[index]) {
                    currPerColumnNames = noCheckboxPerColumnNames;
                    currBusinessColumnNames = noCheckboxBusinessColumnNames;
                    currPerAccessKeys = noCheckboxPerAccessKeys;
                    currBusinessAccessKeys = noCheckboxBusinessAccessKeys;
                }
                let structToPush: IContentBoxMultiTabTableTabRepr = {
                    target: ele.tab_name,
                    display: ele.tab_name,
                    active: (index == 0),
                    data: displayTableData[index],
                    columnNames: [currPerColumnNames, currBusinessColumnNames],
                    accessKeys: [currPerAccessKeys, currBusinessAccessKeys],
                    links: [perlink, bnslink],
                    children: [],
                    miscHeaders: [],
                    innerMiscHeaders: [],
                }
                let statusDropdown = undefined;
                let yearDropdown = {
                    type: "dropdown",
                    options: years,
                    value: currYear,
                    callback: yearDropdownCallback
                }
                if (ele.status != undefined) {
                    statusDropdown = ele.status.map((ele) => ele.status_name);
                }
                structToPush.miscHeaders = [yearDropdown];
                if (statusDropdown != undefined && statusDropdown.length > 1) {
                    statusDropdown.unshift(statusAllText.current);
                    let statusDropdownConfig = {
                        type: "dropdown",
                        options: statusDropdown,
                        value: currStatus[index],
                        callback: statusDropdownCallback
                    } 
                    structToPush.miscHeaders.push(statusDropdownConfig);
                }
                childrenTab.forEach((childEle) => {
                    structToPush.children.push({
                        target: ele.tab_name + "_" + childEle.target,
                        display: childEle.display,
                        active: childEle.active
                    })
                    if (ele.is_show_button != undefined && ele.is_show_button) {
                        if (currStatus[index] != statusAllText.current && structToPush.innerMiscHeaders != undefined) {
                            let currButton = buttonConfig[currStatus[index]];
                            if (currButton != undefined) {
                                structToPush.innerMiscHeaders.push(currButton);
                            }
                        } else {
                            let currButton = buttonConfig[tabConfig.tab_config[index].tab_name];
                            if (currButton != undefined && structToPush.innerMiscHeaders != undefined) {
                                structToPush.innerMiscHeaders.push(currButton);
                            }
                        }
                    }
                })
                tabs.push(structToPush);
            })
        }
        return (
            <ContextBoxMultiTabTable tabs={tabs} hasSearch={true} title="TaxFlow" inputCallback={inputCallback}
                tabCallback={tabCallback} hasDownload={true}/>
        )
    }
    
    
    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getContent()}
                <ScrollTop />
            </div>
        )
    }
}

export default TaskList;
