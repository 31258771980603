import React  from "react";
import { AxiosResponse } from "axios";

import Loading from '../../../../intelws_portal/constructs/elements/Loading';
import { IPageComponentProps } from "../../../../declarations/common";
import { get, getCancelToken, post } from "../../../../intelws_portal/utils/backendInterface";
import { IMenuOptionsConfig } from "../../../App";
import Messages, { IMessageRepr } from "./Dashboard/Messages";
import clientIcon from '../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-13.svg';
import teamIcon from '../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-14.svg';

interface IMatchProps {
    userId: string
}

interface IFetchMessages extends AxiosResponse {
    data: {
        data: IMessageRepr[]
    }
}

interface IPostMessages extends AxiosResponse {
    data: {
        data: IMessageRepr
    }
}

export interface IClientCommunicationProps<T> extends IPageComponentProps<T> {
    mutator: {
        currentPage?: (currentPage: string) => void,
        getMenuOptionsConfig: () => IMenuOptionsConfig
    }
}

function ClientCommunication(props: IClientCommunicationProps<IMatchProps>) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(true);

    const [clientMessages, setClientMessages] = React.useState<IMessageRepr[] | undefined>();
    const [teamMessages, setTeamMessages] = React.useState<IMessageRepr[] | undefined>();

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientCommunication");
        }
        if (props.mutator.getMenuOptionsConfig().client_message) {
            fetchMessages("client");
        }
        if (props.mutator.getMenuOptionsConfig().team_message) {
            fetchMessages("team");
        }
    }, [])

    function fetchMessages(type: "client" | "team") {
        let queryParams = {
            user_id: props.match.params.userId,
            section: "messages",
            type: type
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchMessages>;
        requestResponse.then((response) => {
            if (type == "client") {
                setClientMessages(response.data.data);
            } else {
                setTeamMessages(response.data.data);
            }
        })
    }

    function messageSubmitCallback(message: string, type: "client" | "team") {
        let formData = new FormData();
        if (type == "client" && message != "") {
            formData.append("msg", message);
        } else if (type == "team" && message != "") {
            formData.append("msg", message);
        }
        let queryParams = {
            user_id: props.match.params.userId,
            section: "message",
            type: type
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostMessages>;
        requestResponse.then((response) => {
            if (type == "client") {
                setClientMessages((prevClientMessages) => {
                    if (prevClientMessages != undefined) {
                        return [...prevClientMessages, response.data.data];
                    }
                })
            } else {
                setTeamMessages((prevTeamMessages) => {
                    if (prevTeamMessages != undefined) {
                        return [...prevTeamMessages, response.data.data];
                    }
                })
            }
        })
    }

    function getContent() {
        let messages: JSX.Element[] = [];
        
        if (clientMessages != undefined && teamMessages != undefined) {
            messages.push(
                <div className="col-sm">
                    <Messages display={clientMessages} notif={0}
                        onSubmit={(message) => messageSubmitCallback(message, "client")}
                        title="Client Messages" target="col1" no_of_container={2} icon={clientIcon} id={"text1"}/>
                </div>
            );
            messages.push(
                <div className="col-sm">
                    <Messages display={teamMessages} notif={0}
                        onSubmit={(message) => messageSubmitCallback(message, "team")}
                        title="Team Messages" target="col2" no_of_container={2} icon={teamIcon} id={"text2"}/>
                </div>
            );
        }
        else if(clientMessages != undefined && teamMessages == undefined)
        {
            messages.push(
                <div className="container">
                <div className="col-sm">
                    <Messages display={clientMessages} notif={0}
                        onSubmit={(message) => messageSubmitCallback(message, "client")}
                        title="Communication" target="col1" no_of_container={1} icon={clientIcon}id={"text1"}/>
                </div>
                </div>
            )
        }
        else if (teamMessages != undefined && clientMessages == undefined) {
            messages.push(
                <div className="container">  
                   <div className="col-sm">
                    <Messages display={teamMessages} notif={0}
                        onSubmit={(message) => messageSubmitCallback(message, "team")}
                        title="Team Chat" target="col2" no_of_container={1} icon={teamIcon} id={"text1"}/>
                </div>
                </div>

            )

        }
        return messages;
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
                <div className="row">
                    {getContent()}
                </div>
        )
    }
}

export default ClientCommunication;
