import React, { Component } from "react";
import $ from "jquery";

export interface IMessageRepr {
    id: number,
    is_curr_user: boolean,
    initials: string,
    ms_sent: string,
    ms_date_only: string,
    ms_time_only: string,
    ms_message: string

}

export interface IMessagesProps {
    notif: number,
    target: string,
    title: string,
    display?: IMessageRepr[],
    onSubmit: (message: string) => void,
    no_of_container: number,
    icon?: string,
    id?: string
}

function Messages(props: IMessagesProps) {
    const [message, setMessage] = React.useState("");
    const [wordscount, setWordscount] = React.useState(1000);

    function getContainerClassName(ele: IMessageRepr) {
        if (ele.is_curr_user) {
            return "msg-items current-user-msg";
        } else {
            return "msg-items";
        }
    }

    function buildMessageItems() {
        if (props.no_of_container == 2) {
            var left = { left: "0%" };
            var padding = { paddingLeft: "3%" };
        }

        if (props.display != undefined && props.display.length > 0) {
            return <div className="msg-container">
                {props.display.map((ele) => {
                    if (ele.is_curr_user) {
                        return (
                            <div key={ele.id} className={getContainerClassName(ele)}>
                                <div className="row justify-content-end">
                                    <div className="col-auto" style={{ paddingRight: "0%", maxWidth: "90%"}}>

                                        <div className="msg-box-container shadow-sm">
                                            <div className="msg-name-container">
                                                <h6>{ele.ms_sent}</h6>
                                                <p className="time-stamp">
                                                    <i>{ele.ms_date_only}
                                                        <span>{ele.ms_time_only}</span>
                                                    </i></p>
                                            </div>
                                            <div className="msg-text">
                                                <p>{ele.ms_message}</p>
                                            </div>
                                        </div>
                                        <div className="msg-out-arrow"></div>
                                    </div>
                                    <div className="col-1">
                                        <div className="user-profile">{ele.initials}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div key={ele.id} className={getContainerClassName(ele)}>
                                <div className="row justify-content-start" style={padding}>
                                    <div className="col-1 pl-4">
                                        <div className="user-profile" style={left}>{ele.initials}</div>
                                    </div>
                                    <div className="col-auto pl-0"  style={{maxWidth: "90%"}}>
                                        <div className="msg-in-arrow"></div>
                                        <div className="msg-box-container shadow-sm">
                                            <div className="msg-name-container">
                                                <h6>{ele.ms_sent}</h6>
                                                <p className="time-stamp">
                                                    <i>{ele.ms_date_only}
                                                        <span>{ele.ms_time_only}</span>
                                                    </i></p>
                                            </div>
                                            <div className="msg-text">
                                                <p>{ele.ms_message}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )
                    }

                })}
            </div>
        } else {
            return (
                <div className="no-msg-fond">
                    <p>
                        No Messages
                    </p>
                </div>

            )
        }
    }

    function getNotif() {
        if (props.notif > 0) {
            return <div className="msg-bg client">{props.notif}</div>
        }
    }

    function onChangeFormDisplay(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setMessage(e.target.value);
    }

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (message != "") {
            props.onSubmit(message);
        }
        setMessage("");
        setWordscount(1000)
    }
   
    $('.inputmsg').keypress(function (e) {
        var sendbtn = document.getElementsByClassName("sendbtn");
        for(let ele in sendbtn){
            if (e.ctrlKey) {
                (sendbtn[ele]as HTMLInputElement).click();
            }
        }
      });
    $(`#${props.id}`).keyup((e)=>{
        let msg = document.getElementById(`${props.id}`)?.innerHTML;
        let count = 1000 - (msg?.length ?? 0);
        setWordscount(count)
    })
    return (
        <div className="chat-container" style={{ marginTop: "30px" }}>
            <div className="chat-header panel-heading" >
                <h5 className="header-title">
                <img src={props.icon} className="inactive mr-2" alt="" style={{width: "4%"}}/>
                    {props.title}
                    <span className="badge badge-danger badge-cpasage-notif">
                        {getNotif()}
                    </span>
                </h5>
            </div>
            <div id={props.target} className="panel-collapse collapse show">
                <div className="chat-body-container" style={{ height: "50vh" }}>
                    {buildMessageItems()}
                </div>
                <p style={{position:"absolute", marginTop:"-25px", marginLeft:"2%", color:"#898989", fontSize:"12px",backgroundColor:"white"}}>Remaining characters: {wordscount}</p>
                <div className="chat-footer-container">
                    <form className="chat-footer-form" onSubmit={onSubmit}>
                        <textarea id={props.id} className="inputmsg" value={message} onChange={onChangeFormDisplay} placeholder="Send message" maxLength={1000} />
                        <button className="sendbtn" style={{ border: "1px solid #bec5e4" }}>
                            <i className="fa fa-send" style={{ color: "white" }}></i></button>
                    </form>
                </div>
            </div>
        </div>

    )

}

export default Messages;