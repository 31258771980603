import { AxiosResponse } from "axios";
import React from "react";
import { IPageComponentProps } from "../../../../declarations/common";
import { IMenuOptionsConfig } from "../../../App";
import ContentBox from "../../../../intelws_portal/constructs/elements/ContentBox";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import Tabs, { ITabRepr } from "../../../../intelws_portal/constructs/elements/Tabs";
import { get, getCancelToken } from "../../../../intelws_portal/utils/backendInterface";
import ClientCard from "../clientDashboard/Dashboard/ClientCard";
import ManualTaskDetailsDashboard from "./ManualTaskDetailsDashboard";
import ManualTaskDetailsDashboardTemplates from "./ManualTaskDetailsDashboardTemplates";
import ManualTaskDocuments from "./ManualTaskDocuments";
import ManualTaskSettings from "./ManualTaskSettings";
import ManualTaskSettingsTemplates from "./ManualTaskSettingsTemplates";

interface IMatchProps {
    userId: number
    taskId: number
}

interface IFetchIsTemplateConfig extends AxiosResponse {
    data: boolean
}

export interface IManualTaskDashboardLanding<T> extends IPageComponentProps<T> {
    mutator: {
        currentPage?: (currentPage: string) => void,
        getMenuOptionsConfig: () => IMenuOptionsConfig
    },
}

function ManualTaskDashboardLanding(props: IManualTaskDashboardLanding<IMatchProps>) {
    const ENDPOINT = "/api/manualtasklist/";
    const isUser = React.useRef(props.mutator.getMenuOptionsConfig().is_user as boolean);
    const [manualTaskContent, setManualTaskContent] = React.useState<(string | undefined)[]>(() => {
        if (isUser.current) {
            return [undefined];
        }
        let manualTaskContent = new Array(props.mutator.getMenuOptionsConfig().project_dashboard?.length).fill(undefined);
        manualTaskContent[0] = (props.mutator.getMenuOptionsConfig().project_dashboard as ITabRepr[])[0].target;
        return manualTaskContent;
    });
    const [manualTaskTabs, setManualTaskTabs] = React.useState<ITabRepr[]>(() => {
        if (isUser.current) {
            return [];
        }
        return props.mutator.getMenuOptionsConfig().project_dashboard as ITabRepr[];
    })
    const [isTemplate, setIsTemplate] = React.useState(false);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "manual_task_is_template",
            user_id: props.match.params.userId,
            task_tracking_id: props.match.params.taskId
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchIsTemplateConfig>;
        requestResponse.then((response) => {
            setIsTemplate(response.data);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])


    function resolveTargetToComponent(target?: string) {
        if (target == "projectTaskTabContent") {
            return <ManualTaskDetailsDashboard {...props} />
        } else if (target == "projectDocumentsTabContent") {
            return <ManualTaskDocuments {...props} />
        } else {
            return <Loading />
        }
    }

    function getContent() {
        if (isUser.current) {
            return <ManualTaskDocuments {...props} />
        } else if (isTemplate) {
            return <ManualTaskDetailsDashboardTemplates {...props} />
        } else {
            let contents: { content: JSX.Element }[] = [];
            manualTaskContent.forEach((ele) => {
                contents.push({ content: resolveTargetToComponent(ele) });
            })
            return (
                <Tabs center={true} tabs={manualTaskTabs} contents={contents}
                    tabCallback={(tabName, index) => {
                        setManualTaskContent((prevClientContent) => {
                            let newClientContent = [...prevClientContent];
                            newClientContent[index] = manualTaskTabs[index].target;
                            return newClientContent;
                        })
                    }} />
            )
        }
    }

    function aggregrateContent() {
        return (
            <div >
                {(() => {
                    if (!isUser.current) {
                        return <ClientCard userId={props.match.params.userId} clientContext={false} bottomBorder />
                    }
                })()}
                {(() => {
                    if (isTemplate && !isUser.current) {
                        return <ManualTaskSettingsTemplates {...props} />;
                    } else {
                        return <ManualTaskSettings {...props} isUser={props.mutator.getMenuOptionsConfig().is_user as boolean} />
                    }
                })()}
                {getContent()}
            </div>
        )
    }

    return (
        <div className="body-content-wrapper">
            <ContentBox body={aggregrateContent()} />
        </div>
    )
}

export default ManualTaskDashboardLanding;