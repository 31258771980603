import React from 'react';
import nameProfileActive from '../../../../../external/backPages/img/images/name-profile-active.svg';
import nameProfileInactive from '../../../../../external/backPages/img/images/name-profile-inactive.svg';
import emailActive from '../../../../../external/backPages/img/images/email-active.svg';
import emailInactive from '../../../../../external/backPages/img/images/email-inactive.svg';
import phoneActive from '../../../../../external/backPages/img/images/phone-active.svg';
import phoneInactive from '../../../../../external/backPages/img/images/phone-inactive.svg';

import { HOSTNAME } from "../../../../Urls";
import { get, getCancelToken } from "../../../../../intelws_portal/utils/backendInterface";
import { AxiosResponse } from 'axios';


interface IFetchUserInfoRepr extends AxiosResponse {
    data: {
        name: string,
        email: string,
        phone_num: string
    }
}

export interface IClientCardProps {
    userId: number,
    clientContext: boolean,
    InvoiceCard?: boolean,
    bottomBorder?: boolean
}

function ClientCard(props: IClientCardProps) {
    const ENDPOINT = "api/clientdashboard/";
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNum, setPhoneNum] = React.useState("");

    React.useEffect(() => {
        const cancelTokenSource = getCancelToken();
        let queryParams = {
            user_id: props.userId,
            section: "client_info"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.token, queryParams) as Promise<IFetchUserInfoRepr>;
        requestResponse.then((response) => {
            setName(response.data.name);
            setEmail(response.data.email);
            setPhoneNum(response.data.phone_num);
        })
        return () => {
            cancelTokenSource.cancel();
        }
    }, [])

    function getCardHeader() {
        if (!props.clientContext) {
            if (props.bottomBorder) {
                return (
                    <div className="contact-detail" style={{ textAlign: "center", borderBottom: "1px solid #dee2e6" }}>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <p style={{ color: "black" }}> <img src={nameProfileActive} className="active" alt="" /> &nbsp;&nbsp;&nbsp;
                                {name}</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <p> <img src={emailActive} className="active" alt="" /> &nbsp;&nbsp;&nbsp;
                                <a href={`mailto:${email}`} style={{ color: "black" }}>{email}</a></p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <p><img src={phoneActive} className="active" alt="" /> &nbsp;&nbsp;&nbsp;
                                <a href={`tel:${phoneNum}`} style={{ color: "black" }}>{phoneNum}</a></p>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="contact-detail" style={{ textAlign: "center" }}>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <p style={{ color: "black" }}> <img src={nameProfileActive} className="active" alt="" /> &nbsp;&nbsp;&nbsp;
                                {name}</p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <p> <img src={emailActive} className="active" alt="" /> &nbsp;&nbsp;&nbsp;
                                <a href={`mailto:${email}`} style={{ color: "black" }}>{email}</a></p>
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <p><img src={phoneActive} className="active" alt="" /> &nbsp;&nbsp;&nbsp;
                                <a href={`tel:${phoneNum}`} style={{ color: "black" }}>{phoneNum}</a></p>
                        </div>
                    </div>
                )
            }

        } else if (props.InvoiceCard == true) {
            return (
                <div className="row mt-5" style={{ marginLeft: "20px" }}>
                    <div className="col-auto">
                        <p className="form-label" style={{ float: "left", color: "#707070", fontWeight: 600 }}>Name :</p>
                    </div>
                    <div className="col-6">
                        <p className="form-label" style={{ float: "left", fontSize: "15px" }}>{name}</p>
                    </div>
                    <div className="w-100"></div>
                    <div className="col-auto">
                        <p className="form-label" style={{ float: "left", color: "#707070", fontWeight: 600 }}>Email :</p>
                    </div>

                    <div className="col-6">
                        <p className="form-label" style={{ float: "left", fontSize: "15px" }}>{email}</p>
                    </div>
                    <div className="w-100"></div>
                    {/*<div className="col-auto">
                        <p className="form-label" style={{ float: "left", color: "#707070", fontWeight: 600 }}>Phone :</p>
                    </div>

                    <div className="col-6">
                        <p className="form-label" style={{ float: "left", fontSize: "15px" }}>1234567890</p>
            </div>*/}
                </div>)
        }
        else {
            return (
                <div className="contact-detail row">
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="contact-box ">
                            <img src={nameProfileActive} className="active" alt="" style={{ width: "12%" }} />
                            <img src={nameProfileInactive} className="inactive" alt="" style={{ width: "12%" }} />
                            <p>
                                {name}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="contact-box">
                            <img src={emailActive} className="active" alt="" style={{ width: "10%" }} />
                            <img src={emailInactive} className="inactive" alt="" style={{ width: "10%" }} />
                            <p>
                                {email}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 col-xs-12">
                        <div className="contact-box">
                            <img src={phoneActive} className="active" alt="" style={{ width: "10%" }} />
                            <img src={phoneInactive} className="inactive" alt="" style={{ width: "10%" }} />
                            <p>
                                {phoneNum}
                            </p>
                        </div>
                    </div>
                </div>
            )

        }
    }

    return (
        <div>
            {getCardHeader()}
        </div>
    )
}

export default ClientCard;