import React from "react";
import $ from "jquery";
import { AxiosResponse } from "axios";

import ContentBoxTabTable, { IContentBoxTabTableProps } from '../../../intelws_portal/bundles/ContentBoxTabTable';
import Loading from '../../../intelws_portal/constructs/elements/Loading';
import AddButton from '../../../intelws_portal/constructs/elements/AddButton';
import { ICheckboxRepr, IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import ScrollTop from "../../../intelws_portal/constructs/elements/ScrollTop";
import updateCache from "../../../intelws_portal/utils/updateCache";
import { getCancelToken, get, post } from "../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../declarations/common";
import { getBundledAlertsUI } from "../../../declarations/common_utils";
import ModalTeam from "../modal/ModalTeam";

interface IFetchEmpListResponseRepr extends AxiosResponse {
    data: IDataRepr[],
}

function AdminEmpAction(props: IPageComponentProps) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState("active");
    const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
    const [tableData, setTableData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined, undefined]);
    const [selectedDataId, setSelectedDataId] = React.useState<number[]>([]);
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);
    const cancelTokenSource = React.useRef(getCancelToken());
    
    const ENDPOINT = "/api/emplist/";

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage) {
            props.mutator.currentPage("TeamAction");
        }
        let queryParams = { section: "active" };
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchEmpListResponseRepr>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                newTableData[0] = response.data;
                return newTableData;
            })
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function clearCheckbox(indexToClear: number, clearSelectedId?: boolean, clearTable?: boolean) {
        if ((clearSelectedId == undefined) || (typeof clearSelectedId == "boolean" && clearSelectedId)) {
            setSelectedDataId([]);
        }
        if ((clearTable == undefined) || (typeof clearTable == "boolean" && clearTable)) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currTable = newTableData[indexToClear];
                if (currTable != undefined) {
                    currTable.forEach((ele) => {
                        (ele.selectCheckbox as ICheckboxRepr).checked = false;
                    })
                }
                return newTableData;
            })
        }
    }

    function tabCallback(tabName: string, index: number, prevIndex: number) {
        clearCheckbox(prevIndex);
        let shouldFetch = (tableData[index] == undefined);
        setCurrentTab(tabName);
        setCurrentTabIndex(index);
        if (shouldFetch) {
            let queryParams = { section: tabName };
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchEmpListResponseRepr>;
            requestResponse.then((response) => {
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    newTableData[index] = response.data;
                    return newTableData;
                })
            })
        }
    }

    function buttonCallback(buttonName: string) {
        let movingFrom: number;
        let movingTo: number;
        let queryParams: { section?: "active" | "inactive" | "archive" | "delete" } = {};
        let execute = false;
        if (buttonName == "Activate") {
            queryParams = { section: "active" };
            execute = true;
            movingFrom = 1;
            movingTo = 0;
        } else if (buttonName == "Inactivate") {
            queryParams = { section: "inactive" };
            if (currentTab == "active") {
                movingFrom = 0;
                movingTo = 1;
            } else {
                movingFrom = 2;
                movingTo = 1;
            }
            execute = true;
        } else if (buttonName == "Archive") {
            queryParams = { section: "archive" };
            movingFrom = 1;
            movingTo = 2;
            execute = true;
        } else if (buttonName == "Delete") {
            queryParams = { section: "delete" };
            movingFrom = 2;
            movingTo = -1;
            execute = true;
        } 
        if (execute) {
            let formData = new FormData();
            formData.append("emp_ids", JSON.stringify(selectedDataId));
            const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                clearCheckbox(currentTabIndex, false);
                setTableData((prevTableData) => {
                    let newTableData = [...prevTableData];
                    let currTable = newTableData[currentTabIndex];
                    if (currTable != undefined) {
                        let records = currTable.filter((ele) => {
                            return selectedDataId.includes(ele.id); 
                        })
                        newTableData = updateCache(newTableData, movingFrom, movingTo, records);
                    }
                    return newTableData;
                })
                clearCheckbox(currentTabIndex, true, false);
            })
        }
    }

    function inputCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "selectCheckbox" && typeof value == "boolean") {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let currTable = newTableData[currentTabIndex];
                if (currTable != undefined) {
                    let rowObj = currTable[rowIndex];
                    (rowObj[accessKey] as ICheckboxRepr).checked = value;
                    setSelectedDataId((prevSelectedDataId) => {
                        let newSelectedDataId = [...prevSelectedDataId];
                        if (value) {
                            newSelectedDataId.push(rowObj.id);
                        } else {
                            newSelectedDataId.splice(newSelectedDataId.findIndex((ele) => {
                                return ele == rowObj.id;
                            }), 1);
                        }
                        return newSelectedDataId;
                    })
                }
                return newTableData;
            })
        } else {
            let queryParams = {
                section: value,
                user_id: (tableData[0] as IDataRepr[])[rowIndex].id
            }
            post(ENDPOINT, new FormData(), cancelTokenSource.current.token, queryParams);
        }
    }

    function teamAddCallback(data?: IDataRepr, success?: string[], fail?: string[]) {
        if (data != undefined) {
            setTableData((prevTableData) => {
                let newTableData = [...prevTableData];
                let activeTableData = newTableData[0];
                if (activeTableData != undefined) {
                    newTableData[0] = [data, ...activeTableData];
                }
                return newTableData;
            })
        }
        if (success != undefined && fail != undefined) {
            setSuccess(success);
            setFail(fail);
        }
    }

    function getContent() {
        let columnName = ['', 'First', 'Last', 'Email', 'Manager', 'When Created', 'Last Login', 'Code', 'Link'];
        let accessKey = ['selectCheckbox', 'emp_first_name', 'emp_last_name', 'emp_email', 'emp_mgr_name', 'emp_create_time', 'emp_last_login', 'code', 'teamActionLink'];
        let tabs = [{ target: "Active", active: true, display: "Active" },
        { target: "Inactive", active: false, display: "Inactive" },
        { target: "Archive", active: false, display: "Archive" }];
        let buttons = [
            {
                target: "Active", buttons: [{ buttonName: 'Inactivate' },
                ]
            },
            {
                target: "Inactive", buttons: [{ buttonName: 'Activate' },
                { buttonName: 'Archive' }
                ]
            },
            {
                target: "Archive", buttons: [{ buttonName: 'Inactivate' },
                { buttonName: 'Delete' }
                ]
            }
        ];
        let columnNames = [columnName, columnName, columnName];
        let accessKeys = [accessKey, accessKey, accessKey];
        let propObject: IContentBoxTabTableProps = {
            tabs: tabs,
            contents: tableData,
            columnNames: columnNames,
            accessKeys: accessKeys,
            title: "Team",
            buttons: buttons,
            miscHeaders: <AddButton buttonValue={<i className="fa fa-plus"></i>}
                onClick={() => { $('#teamAddModal').modal('show'); }} />,
            hasRecords: true,
            hasSearch: true,
            tabCallback: tabCallback,
            buttonCallback: buttonCallback,
            inputCallback: inputCallback
        };
        return (<ContentBoxTabTable {...propObject} />)
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
                <ModalTeam mutator={teamAddCallback} />
                <ScrollTop />
            </div>
        )

    }
}

export default AdminEmpAction;
