import React from "react";
import { AxiosResponse } from "axios";

import ContentBoxTabTable from "../../../../intelws_portal/bundles/ContentBoxTabTable";
import Loading from "../../../../intelws_portal/constructs/elements/Loading";
import { IDataRepr } from "../../../../intelws_portal/constructs/elements/Table";
import { get, getCancelToken } from "../../../../intelws_portal/utils/backendInterface";

import { IPageComponentProps } from "../../../../declarations/common";

interface IFetchTemplateData extends AxiosResponse {
    data: IDataRepr[]
}

function ClientOrganizer(props: IPageComponentProps) {
    const ENDPOINT = "api/settings/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [templateData, setTemplateData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined]);

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("ClientOrganizer");
        }
        let queryParams = {
            section: "template_fetch",
            template_subsection: "personal"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchTemplateData>;
        requestResponse.then((response) => {
            setIsLoaded(true);
            setTemplateData((prevTemplateData) => {
                let newTemplateData = [...prevTemplateData];
                newTemplateData[0] = response.data;
                return newTemplateData;
            })
        })
    }, [])

    function templateTabCallback(tab: string) {
        let tabIndex = -1;
        let queryParams = {
            section: "template_fetch",
            template_subsection: "personal"
        }
        if (tab == "organizerindividual" && templateData[0] == undefined) {
            tabIndex = 0;
        } else if (tab == "organizerbusiness" && templateData[1] == undefined) {
            queryParams.template_subsection = "business";
            tabIndex = 1;
        }
        if (tabIndex != -1) {
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchTemplateData>;
            requestResponse.then((response) => {
                setTemplateData((prevTemplateData) => {
                    let newTemplateData = [...prevTemplateData];
                    newTemplateData[tabIndex] = response.data;
                    return newTemplateData;
                })
            })
        }
    }

    function getTemplateContent() {
        let tabConfig = [
            { target: "organizerIndividual", display: "Individual", active: true },
            { target: "organizerBusiness", display: "Business", active: false }
        ];
        let columnName = ["FileName", "Action"];
        let accessKey = ["at_file_name", "actionButton"];
        let columnNames = [columnName, columnName];
        let accessKeys = [accessKey, accessKey];
        return (
            <div className="body-content-wrapper clearfix" style={{ paddingTop: "16px" }}>
                <ContentBoxTabTable title="Organizer" columnNames={columnNames}
                    tabs={tabConfig} accessKeys={accessKeys} hasSearch={true} contents={templateData}
                    tabCallback={templateTabCallback} hasRecords={true} />
            </div>
        )
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="container-fluid">
                {getTemplateContent()}
            </div>
        )
    }
}

export default ClientOrganizer;
