import React from "react";
import { CSSProperties } from "react";
import { render } from "react-dom";
import $ from "jquery";
import Icon, {IIconProps} from "../icons/Icon";

//Types
export interface IButtonProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    buttonValue: string | IIconProps,
    active?: boolean,
    table_btn_flag?:boolean,
    style?: CSSProperties
}

//Type Guards
export function isIIconRepr(obj: any): obj is IIconProps {
    return (obj as IIconProps).icon != undefined;
}

function Button(props: IButtonProps) {
    const [toolTipText, setToolTipText] = React.useState("");
    const [isToolTip, setIsToolTip] = React.useState(false);

    React.useEffect(() => {
        if (isIIconRepr(props.buttonValue)) {
            if (props.buttonValue.isToolTipDisplay != undefined && !props.buttonValue.isToolTipDisplay) {
                setIsToolTip(false);
                setToolTipText("");
            } else {
                setIsToolTip(true);
                setToolTipText(props.buttonValue.icon);
            }
        } else {
            setIsToolTip(false);
            setToolTipText("");
        }
    }, [props.buttonValue])

    function getButtonValue() {
        if (isIIconRepr(props.buttonValue)) {
            return <Icon icon={props.buttonValue.icon} />;
        }
        return props.buttonValue;
    }
    
    function getDisabled() {
        if (props.active == undefined) {
            return false;
        } else {
            return !props.active;
        }
    }

    function getToolTip() {
        if (isToolTip && !getDisabled()) {
            return <span className="tooltiptext1">{toolTipText}</span>;
        }
    }
   
    const tablebtn = props.table_btn_flag?"btn btn-secondary-add-cpasage ml-0 mr-4 mt-0 mb-0 tooltip1":"btn btn-secondary-add-cpasage";

    return (
        
        <button onClick={props.onClick} className={tablebtn}
            disabled={getDisabled()} style={props.style} >{getButtonValue()}{getToolTip()}</button>
        
    )
}

export default Button;
