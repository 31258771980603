import React from "react";
import { AxiosResponse } from "axios";
import $ from "jquery";

import ContextBoxTable from "../../../intelws_portal/bundles/ContentBoxTable";
import ContentBoxTabTable from "../../../intelws_portal/bundles/ContentBoxTabTable";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import ContentBoxAdv from "../../../intelws_portal/constructs/elements/ContentBoxAdv"
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import AddButton from "../../../intelws_portal/constructs/elements/AddButton";
import Button from "../../../intelws_portal/constructs/elements/Button";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";
import ModalFilePicker from "../../../intelws_portal/constructs/modal/ModalFilePicker";

import { IPageComponentProps } from "../../../declarations/common";
import ModalUploadTemplate from "../modal/ModalUploadTemplate";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import { getBundledAlertsUI, parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../declarations/common_utils";
import Tabs from "../../../intelws_portal/constructs/elements/Tabs";
import ContentBoxTable from "../../../intelws_portal/bundles/ContentBoxTable";
import ModalBillingContact from "../modal/ModalBillingContact";
import ModalPaymentMethod from "../modal/ModalPaymentMethod";
import ModalReviewLinkAdd from "../modal/ModalReviewLinkAdd";
import { confirm } from "../../../intelws_portal/constructs/elements/WindowConfirm";
import { IAddressRepr, IPaymentMethodRepr, isCard } from "../../../intelws_portal/utils/payments/paymentTypes";

import visa from "../../card_brand_logo/visa.png";
import mastercard from "../../card_brand_logo/mastercard.png";
import discover from "../../card_brand_logo/discover.jpeg";
import americanExpress from "../../card_brand_logo/american_express.png";
import unionPay from "../../card_brand_logo/union_pay.svg";
import dinersClub from "../../card_brand_logo/diners_club.svg";
import jcb from "../../card_brand_logo/jcb.svg";
import creditCard from "../../card_brand_logo/credit_card.png";
import { cloneDeep } from "lodash";

const SETTINGS_ENDPOINT = "api/settings/";

interface ISocialMediaLinksRepr {
    go_link: string,
    ye_link: string,
    fb_link: string,
    ig_link: string,
    li_link: string,
    tw_link: string
}

interface IAccessLevelMappingRepr {
    managerCheckbox: "manager",
    employeeCheckbox: "employee",
    dirEmployeeCheckbox: "direct_employee",
    recepCheckbox: "recep"
}

interface IBillingInformation {
    accrued_charge: {
        total: number,
        days_left: number,
        client: {
            num: number,
            charge: number
        },
        business: {
            num: number,
            charge: number
        },
        esign: {
            num: number,
            charge: number
        },
        bank_account_verify: {
            num: number,
            charge: number
        }
    },
    billing_contact: {
        is_set: boolean,
        address?: IAddressRepr
    },
    billing_history: IDataRepr[]
    payment_methods: IPaymentMethodRepr[]
}

interface IFetchAccessData {
    access_settings: IDataRepr[],
    access_level_mapping: IAccessLevelMappingRepr
}

interface IFetchAccess extends AxiosResponse {
    data: IFetchAccessData
}

interface IFetchTemplate extends AxiosResponse {
    data: IDataRepr[]
}

interface IFetchClientLink extends AxiosResponse {
    data: {
        data: {
            display: string,
            internal: string
        }[],
        sm_links: ISocialMediaLinksRepr
    }
}

interface IFetchBillingInformation extends AxiosResponse {
    data: {
        data: IBillingInformation
    }
}

interface IFetchCPALogo extends AxiosResponse {
    data: {
        logo_url?: string
    }
}

// interface IFetchReviewLink extends AxiosResponse {
//     data: {
//         data: IDataRepr[]
//     }
// }

interface IFetchReviewLinkData extends AxiosResponse {
    data: {
        data: IDataRepr[],
        is_edit_review: boolean,
        is_delete_review: boolean
    }
}
// interface IFetchReviewLinkEdit extends AxiosResponse {
//     data: {
//         data: IDataRepr
//     }
// }

interface IFetchReviewLinkDetails extends AxiosResponse {
    data : {
        data : IDataRepr
    }
}

interface IPostTableData extends IDataRepr {
    id: number,
    at_file_name: string,
    at_upload_time: string,
    actionButton: {
        type: "button",
        action: "newWindow",
        display: {
            icon: "View" | "file_download"
        },
        url: string
    }[]
}

interface IPostUploadCPALogo extends AxiosResponse {
    data: {
        logo_url: string
    }
}

export interface IPostPaymentSettings {
    data: {
        data: IDataRepr[]
    }
}

export interface IGoogleIntegration extends AxiosResponse {
    data: {
        is_show: boolean,
        is_integrated: boolean,
        url: string
    }
}

export interface IStripeIntegration extends AxiosResponse {
    data: {
        is_show: boolean,
        setup_step: number,
        verification_string: string,
        url: string
    }
}

export interface IPostCreateCharge extends AxiosResponse {
    data: {
        data: IDataRepr
    }
}

export interface IPostUploadTemplateQueryResponseRepr {
    per_data: IPostTableData[],
    bns_data: IPostTableData[],
    fail?: string[],
    success?: string[]
}

function isString(obj: any): obj is string {
    return typeof obj == "string";
}

export interface IAdminSettingsProps extends IPageComponentProps {
    cpaLogoUrl?: string,
    setCpaLogoUrl: React.Dispatch<React.SetStateAction<string | undefined>>
}

function AdminSettings(props: IAdminSettingsProps) {
    const BILLING_ENDPOINT = "/api/billing/";
    const [isLoaded, setIsLoaded] = React.useState(false);

    const [reviewIndex, setReviewIndex] = React.useState<number>();
    const [reviewLink, setReviewLink] = React.useState<string>();
    const [reviewType, setReviewType] = React.useState<string>();

    const [tabConfig, setTabConfig] = React.useState(() => {
        let tabConfig = [{ target: "general", display: "General", active: false },
        { target: "templates", display: "Templates", active: false },
        { target: "integrations", display: "Integrations", active: false },
        { target: "billing", display: "Billing", active: false}]
        let currHash = window.location.hash;
        let newTabIndex = -1;
        if (currHash != "") {
            currHash = currHash.substr(1);
            newTabIndex = tabConfig.findIndex((ele) => {
                return ele.target == currHash;
            })
        }
        if (newTabIndex == -1) {
            currHash = tabConfig[0].target;
            newTabIndex = 0;
        }
        tabConfig[newTabIndex].active = true;
        return tabConfig;
    });
    const [accessSettings, setAccessSettings] = React.useState<IDataRepr[]>([]);
    const [socialMediaLinks, setSocialMediaLinks] = React.useState<ISocialMediaLinksRepr>({
        go_link: "",
        ye_link: "",
        fb_link: "",
        ig_link: "",
        li_link: "",
        tw_link: ""
    });
    const [isSocialMediaEdit, setIsSocialMediaEdit] = React.useState(false);
    const [socialMediaErrorObj, setSocialMediaErrorObj] = React.useState(getEmptySocialMediaErrorObj);
    const [clientLink, setClientLink] = React.useState<{ display: string, internal: string }[]>([]);
    const [showGoogleIntegration, setShowGoogleIntegration] = React.useState(false);
    const [googleIntegrationLink, setGoogleIntegrationLink] = React.useState("");
    const [googleIntegration, setGoogleIntegration] = React.useState<string | boolean | undefined>();
    const [showStripeIntegration, setStripeIntegration] = React.useState(false);
    const [stripeIntegrationLink, setStripeIntegrationLink] = React.useState("");
    const [stripeIntegrationVerificationString, setStripeIntegrationVerificationString] = React.useState("");
    const [stripeIntegrationStep, setStripeIntegrationStep] = React.useState<number>(-1);
    const [templateData, setTemplateData] = React.useState<(IDataRepr[] | undefined)[]>([undefined, undefined])
    const [templateCurrTab, setTemplateCurrTab] = React.useState(0);
    const [reviewLinkData, setReviewLinkData] = React.useState<IDataRepr[]>([]);
    const [isEditReview, setIsEditReview] = React.useState(false);
    const [isDeleteReview, setIsDeleteReview] = React.useState(false);
    const [showLogoForm, setShowLogoForm] = React.useState(false);
    const [cpaLogoValue, setCpaLogoValue] = React.useState<File | undefined>(); //Controlled state variable for the file field for logo upload

    const [paymentMethodSettings, setPaymentMethodSettings] = React.useState<IDataRepr[]>();

    const [billingInfo, setBillingInfo] = React.useState<IBillingInformation | undefined>(undefined);
    

    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());
    const accessLevelMapping = React.useRef({
        managerCheckbox: "manager",
        employeeCheckbox: "employee",
        dirEmployeeCheckbox: "direct_employee",
        recepCheckbox: "recep"
    })

    React.useEffect(() => {
        if (props.mutator != undefined && props.mutator.currentPage != undefined) {
            props.mutator.currentPage("");
        }
        const accessReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "access" }) as Promise<IFetchAccess>;
        const templateReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "template_fetch", template_subsection: "personal" }) as Promise<IFetchTemplate>;
        const clientSignupReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "client_link" }) as Promise<IFetchClientLink>;
        const googleIntegrationReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "google_integration_status" }) as Promise<IGoogleIntegration>;
        const stripeIntegrationReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "stripe_integration_status" }) as Promise<IStripeIntegration>;
        const reviewLinkReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "review_link" }) as Promise<IFetchReviewLinkData>;
        const paymentMethodSettingsReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "payment_settings" }) as Promise<IPostPaymentSettings>
        Promise.all([accessReqResp, templateReqResp, clientSignupReqResp, googleIntegrationReqResp, reviewLinkReqResp, stripeIntegrationReqResp, paymentMethodSettingsReqResp])
            .then((result) => {
                setIsLoaded(true);
                setAccessSettings(result[0].data.access_settings);
                setTemplateData((prevTemplateData) => {
                    let newTemplateData = [...prevTemplateData];
                    newTemplateData[0] = result[1].data;
                    return newTemplateData;
                    
                })
                setClientLink(result[2].data.data);
                setSocialMediaLinks(result[2].data.sm_links);
                if (!result[3].data.is_integrated && result[3].data.url != undefined) {
                    setGoogleIntegration(result[3].data.url);
                } else {
                    setGoogleIntegration(false);
                }
                setGoogleIntegrationLink(result[3].data.url);
                setShowGoogleIntegration(result[3].data.is_show);

                setReviewLinkData(result[4].data.data);
                setIsEditReview(result[4].data.is_edit_review);
                setIsDeleteReview(result[4].data.is_delete_review);
                
                setStripeIntegration(result[5].data.is_show);
                setStripeIntegrationLink(result[5].data.url);
                setStripeIntegrationVerificationString(result[5].data.verification_string);
                setStripeIntegrationStep(result[5].data.setup_step);
                setPaymentMethodSettings(result[6].data.data);
            })
        let currHash = window.location.hash;
        if (currHash != "") {
            currHash = currHash.substr(1);
            if (currHash == "billing") {
                fetchBillingInfo();
            }
        }
        
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    function getEmptySocialMediaErrorObj() {
        return {
            go_link: {
                isError: false,
                errorText: ""
            },
            ye_link: {
                isError: false,
                errorText: ""
            },
            fb_link: {
                isError: false,
                errorText: ""
            },
            ig_link: {
                isError: false,
                errorText: ""
            },
            li_link: {
                isError: false,
                errorText: ""
            },
            tw_link: {
                isError: false,
                errorText: ""
            }
        }
    }

    function accessCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        let queryParams = {
            section: "access"
        }
        let formData = new FormData();
        formData.append("access_type", (accessSettings[rowIndex] as any)["access_type"]);
        formData.append("access_level", (accessLevelMapping.current as any)[accessKey]);
        if (value != undefined) {
            formData.append("value", value.toString());
        }
        const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token, queryParams)
        requestResponse.then((response) => {
            setAccessSettings((prevAccessSettings) => {
                let newAccessSettings = [...prevAccessSettings];
                (newAccessSettings[rowIndex][accessKey] as any).checked = value;
                return newAccessSettings;
            })
        })
    }
    
    function paymentMethodAccessCallback(rowIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "checkbox" && typeof value == "boolean") {
            let queryParams = {
                section: "payment_settings"
            }
            let formData = new FormData();
            if (paymentMethodSettings != undefined) {
                formData.append("payment_method", (paymentMethodSettings[rowIndex] as any)["paymentMethod"]);
            }
            formData.append("value", JSON.stringify(value));
            const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setPaymentMethodSettings((prevPaymentMethodSettings) => {
                    if (prevPaymentMethodSettings != undefined) {
                        let newPaymentMethodSettings = [...prevPaymentMethodSettings];
                        (newPaymentMethodSettings[rowIndex]["checkbox"] as any).checked = value;
                        return newPaymentMethodSettings;
                    }
                })
            })
        }
    }

    function getAccessContent() {
        let columnNames = ['Setting', 'Manager', 'Employee', 'Direct Employee', 'Front Office'];
        let accessKeys = ['settingsName', 'managerCheckbox', 'employeeCheckbox', 'dirEmployeeCheckbox', 'recepCheckbox'];
        let title = "Access Settings";
        let hasSearch = false;
        let hasRecords = false;
        return (
            <div className="box-col col-md-6">
                <div className="contact-box">
                    <ContextBoxTable tableData={accessSettings}
                        columnNames={columnNames} accessKeys={accessKeys}
                        title={title} hasSearch={hasSearch} hasRecords={hasRecords}
                        inputCallback={accessCallback} />
                </div>
            </div>
        )
    }

    function getPaymentMethodAccessContent() {
        let columnNames = ["Payment Method", ""];
        let accessKeys = ["paymentMethodName", "checkbox"];
        let title = "Invoice Payment Settings";
        let hasSearch = false;
        let hasRecords = false;
        return (
            <div className="box-col col-md-6">
                <div className="contact-box">
                    <ContextBoxTable tableData={paymentMethodSettings}
                        columnNames={columnNames} accessKeys={accessKeys}
                        title={title} hasSearch={hasSearch} hasRecords={hasRecords}
                        inputCallback={paymentMethodAccessCallback} />
                </div>
            </div>
        )
    }

    function reviewLinkCallBack(absoluteIndex: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (accessKey == "action" && typeof value == "string") {
            if (value == "deleteReview") {
                confirm("Are you sure you want to delete this review link ?").then(() => {
                    let queryParams = {
                        section: "del_review_link",
                    }
                    let formData = new FormData();
                    let review_index = absoluteIndex;
                    formData.append("review_index", review_index.toString())
                    const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IFetchReviewLinkData>;
                    requestResponse.then((response) => {
                        console.log("del response:::", response.data.data)
                        setReviewLinkData((prevTableData) => {
                            let newTableData =  [...prevTableData];
                            let indexNumber = absoluteIndex;
                            newTableData.splice(indexNumber, 1);
                            return newTableData;
                        })

                    })
                })
            }

            if (value == "editReview") {
                let queryParams = {
                    section: "edit_review_link",
                    review_index : absoluteIndex.toString()
                }
                const requestResponse = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchReviewLinkDetails>;
                requestResponse.then((response) => {
                    console.log("edit response:::", response.data.data)
                    setReviewIndex(absoluteIndex)
                    setReviewLink(response.data.data.link as string)
                    setReviewType(response.data.data.type as string)
                    $('#reviewLinkAddModal').modal('show')
                })
                
            }
        }
    }

    function getReviewLinkContent() {
        let columnNames = ["Review Type", "Review Link"];
        let accessKeys = ["type", "link"];

        if (isEditReview && isDeleteReview) {
            columnNames.push("Action");
            accessKeys.push("action")

        }
        let title = "Review Links";
        let hasSearch = false;
        let hasRecords = false;
        return (
            <div className="box-col col-md-6">
                <ContextBoxTable tableData={reviewLinkData}
                    columnNames={columnNames} accessKeys={accessKeys}
                    title={title} hasSearch={hasSearch} hasRecords={hasRecords}
                    inputCallback={reviewLinkCallBack}
                    miscHeaders={<AddButton buttonValue={<i className="fa fa-plus"></i>} onClick={() => $('#reviewLinkAddModal').modal('show')} />} />
            </div>
        )
    }

    function socialMediaformFieldChange(e: React.ChangeEvent<HTMLInputElement>, section: "Google" | "Yelp"| "Facebook" | "Instagram" | "Linkedin" | "Twitter") {
        setSocialMediaLinks((prevSocialMediaLink) => {
            let newSocialMediaLink = { ...prevSocialMediaLink };
            if (section == "Google") {
                newSocialMediaLink.go_link = e.target.value;
            } else if (section == "Yelp") {
                newSocialMediaLink.ye_link = e.target.value;
            } else if (section == "Facebook") {
                newSocialMediaLink.fb_link = e.target.value;
            } else if (section == "Instagram") {
                newSocialMediaLink.ig_link = e.target.value;
            } else if (section == "Linkedin") {
                newSocialMediaLink.li_link = e.target.value;
            } else if (section == "Twitter") {
                newSocialMediaLink.tw_link = e.target.value;
            }
            return newSocialMediaLink;
        })
    }

    function normalizeString(value: string | undefined) {
        if (value == undefined) {
            return ""
        }
        return value;
    }

    function socialMediaButtonCallback(eventType: "Update" | "Edit") {
        if (eventType == "Edit") {
            setIsSocialMediaEdit(true);
        } else {
            let queryParams = {
                section: "set_social_media_links"
            }
            let formData = new FormData();
            formData.append("go_link", normalizeString(socialMediaLinks.go_link));
            formData.append("ye_link", normalizeString(socialMediaLinks.ye_link));
            formData.append("fb_link", normalizeString(socialMediaLinks.fb_link));
            formData.append("ig_link", normalizeString(socialMediaLinks.ig_link));
            formData.append("li_link", normalizeString(socialMediaLinks.li_link));
            formData.append("tw_link", normalizeString(socialMediaLinks.tw_link));
            const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token, queryParams);
            requestResponse.then(() => {
                setIsSocialMediaEdit(false);
                setSocialMediaErrorObj(getEmptySocialMediaErrorObj());
            }).catch((error) => {
                let [success, fail, failResponse] = parseQueryResponseOnFail(error);
                let errorObj = getEmptySocialMediaErrorObj();
                parseQueryResponseOnFailResponse(errorObj, failResponse);
                setSocialMediaErrorObj(errorObj);
            })
        }
    }

    function getSocialMediaContent() {
        let jsxBody: JSX.Element[] = [];

        jsxBody.push(
        <React.Fragment>
            <div className="row">
                <div className="col-md-1 col-sm-1 pr-0 mt-1">
                    <i className="fa fa-google" style={{fontSize:"22px",paddingRight:"0px",verticalAlign:"middle"}}></i>
                </div>
                <div className="col-md-11 col-sm-11 pl-0">
                    <ModalInputText onChange={(e) => socialMediaformFieldChange(e, "Google")} value={socialMediaLinks.go_link}
                    title={"Google Link"} disabled={!isSocialMediaEdit} borderBottom={socialMediaLinks.go_link == "" || isSocialMediaEdit}
                    isError={socialMediaErrorObj.go_link.isError} errorText={socialMediaErrorObj.go_link.errorText} style={{paddingLeft:"0px", minHeight:"37px"}}/>
                </div>            
            </div>
        </React.Fragment>)
        jsxBody.push(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-1 col-sm-1 pr-0 mt-1">
                        <i className="fa fa-yelp" style={{fontSize:"22px",paddingRight:"0px",verticalAlign:"middle"}}></i>
                    </div>
                    <div className="col-md-11 col-sm-11 pl-0">
                        <ModalInputText onChange={(e) => socialMediaformFieldChange(e, "Yelp")} value={socialMediaLinks.ye_link}
                        title={"Yelp Link"} borderBottom={socialMediaLinks.ye_link == "" || isSocialMediaEdit} disabled={!isSocialMediaEdit}
                        isError={socialMediaErrorObj.ye_link.isError} errorText={socialMediaErrorObj.ye_link.errorText} style={{paddingLeft:"0px", minHeight:"37px"}}/>
                    </div>            
                </div>
            </React.Fragment>)
        jsxBody.push(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-1 col-sm-1 pr-0 mt-1">
                        <i className="fa fa-facebook" style={{fontSize:"22px",paddingRight:"0px",verticalAlign:"middle"}}></i>
                    </div>
                    <div className="col-md-11 col-sm-11 pl-0">
                        <ModalInputText onChange={(e) => socialMediaformFieldChange(e, "Facebook")} value={socialMediaLinks.fb_link}
                        title={"Facebook Link"} borderBottom={socialMediaLinks.fb_link == "" || isSocialMediaEdit} disabled={!isSocialMediaEdit}
                        isError={socialMediaErrorObj.fb_link.isError} errorText={socialMediaErrorObj.fb_link.errorText} style={{paddingLeft:"0px", minHeight:"37px"}}/>
                    </div>            
                </div>
            </React.Fragment>)
        jsxBody.push(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-1 col-sm-1 pr-0 mt-1">
                        <i className="fa fa-instagram" style={{fontSize:"22px",paddingRight:"0px",verticalAlign:"middle"}}></i>
                    </div>
                    <div className="col-md-11 col-sm-11 pl-0">
                        <ModalInputText onChange={(e) => socialMediaformFieldChange(e, "Instagram")} value={socialMediaLinks.ig_link}
                        title={"Instagram Link"} borderBottom={socialMediaLinks.ig_link == "" || isSocialMediaEdit} disabled={!isSocialMediaEdit}
                        isError={socialMediaErrorObj.ig_link.isError} errorText={socialMediaErrorObj.ig_link.errorText} style={{paddingLeft:"0px", minHeight:"37px"}}/>
                    </div>            
                </div>
            </React.Fragment>)
        jsxBody.push(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-1 col-sm-1 pr-0 mt-1">
                        <i className="fa fa-linkedin" style={{fontSize:"22px",paddingRight:"0px",verticalAlign:"middle"}}></i>
                    </div>
                    <div className="col-md-11 col-sm-11 pl-0">
                        <ModalInputText onChange={(e) => socialMediaformFieldChange(e, "Linkedin")} value={socialMediaLinks.li_link}
                        title={"Linkedin Link"} borderBottom={socialMediaLinks.li_link == "" || isSocialMediaEdit} disabled={!isSocialMediaEdit}
                        isError={socialMediaErrorObj.li_link.isError} errorText={socialMediaErrorObj.li_link.errorText} style={{paddingLeft:"0px", minHeight:"37px"}}/>
                    </div>            
                </div>
            </React.Fragment>)
        jsxBody.push(
            <React.Fragment>
                <div className="row">
                    <div className="col-md-1 col-sm-1 pr-0 mt-1">
                        <i className="fa fa-twitter" style={{fontSize:"22px",paddingRight:"0px",verticalAlign:"middle"}}></i>
                    </div>
                    <div className="col-md-11 col-sm-11 pl-0">
                        <ModalInputText onChange={(e) => socialMediaformFieldChange(e, "Twitter")} value={socialMediaLinks.tw_link}
                        title={"Twitter Link"} borderBottom={socialMediaLinks.tw_link == "" || isSocialMediaEdit} disabled={!isSocialMediaEdit}
                        isError={socialMediaErrorObj.tw_link.isError} errorText={socialMediaErrorObj.tw_link.errorText} style={{paddingLeft:"0px", minHeight:"37px"}}/>
                    </div>            
                </div>
            </React.Fragment>)

        
        function getTitle() {
            let buttonShow: JSX.Element | undefined = undefined;
            if (isSocialMediaEdit) {
                buttonShow = <AddButton buttonValue={<i className="fa fa-floppy-o"></i>} onClick={() => socialMediaButtonCallback("Update")} toolTipText={"Save"}/>
            } else {
                buttonShow = <AddButton buttonValue={<i className="fa fa-edit"></i>} onClick={() => socialMediaButtonCallback("Edit")} toolTipText={"Edit"}/>
            }

            return (
                <React.Fragment>
                    Social Media Links {buttonShow}
                </React.Fragment>
            )
        }

        return (
            <div className="contact-box">
                <ContentBoxAdv title={getTitle()} body={jsxBody} />
            </div>
        )
    }

    function disconnectGoogle() {
        let queryParams = {
            section: "disconnect_google_integration"
        }
        const requestResponse = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, queryParams);
        requestResponse.then(() => {
            setGoogleIntegration(googleIntegrationLink);
        })
    }

    function getGoogleContent() {
        if (!showGoogleIntegration) {
            return undefined;
        }

        if (googleIntegration == undefined) {
            return <Loading />
        } else {
            if (typeof googleIntegration == "boolean") {
                return (
                    <div className="contact-box" style={{ height: "47%", marginBottom: "3%" }}>
                        <ContentBoxAdv title={"Integrate Google Account"}
                            body={<a id='addClientLink' href="#" onClick={disconnectGoogle}>
                                Disconnect </a>} />
                    </div>
                )
            }
            return (
                <div className="contact-box" style={{ height: "47%", marginBottom: "3%" }}>
                    <ContentBoxAdv title={"Integrate Google Account"}
                        body={<a id='googleLink' href="#" onClick={() => window.open(googleIntegration, "_blank")}>
                            Google Button </a>} />
                </div>
            )
        }
    }

    function getStripeContent() {
        if (!showStripeIntegration) {
            return undefined;
        } else {
            let displayMessage = "";
            if (stripeIntegrationStep == 0) {
                displayMessage = "Start Onboard Process"
            } else if (stripeIntegrationStep == 1) {
                displayMessage = "Continue Onboard Process"
            } else if (stripeIntegrationStep == 2) {
                displayMessage = "Account Integrated"
            }
            if (stripeIntegrationStep == 0 || stripeIntegrationStep == 1) {
                return (
                    <div className="contact-box" style={{ height: "47%", marginBottom: "3%" }}>
                        <ContentBoxAdv title={"Onboard Stripe Account"}
                            body={
                                <React.Fragment>
                                    <p></p>
                                    <p>{stripeIntegrationVerificationString}</p>
                                    <p></p>
                                    <a id='stripeLink' href="#" onClick={() => window.open(stripeIntegrationLink, "_blank")}>{displayMessage} </a>
                                </React.Fragment>
                            } />
                    </div>
                )
            } else {
                return (
                    <div className="contact-box" style={{ height: "47%", marginBottom: "3%" }}>
                        <ContentBoxAdv title={"Onboard Stripe Account"}
                            body={<p>{displayMessage}</p>} />
                    </div>
                )
            }
        }
    }

    function getSignupContent() {
        if (clientLink.length == 0) {
            return <Loading />
        } else {
            let jsxBody: JSX.Element[] = [];
            clientLink.forEach((ele,i) => {
                let myid = "addClientLink" + i;
                let ttId = "tt" + i;
                let ttTex1 = "Copy to Clipboard";
                let ttTex2 = "Copied"
                jsxBody.push(
                <div>
                    <a id={myid} href="#" onClick={() => window.open(ele.internal, "_blank")}>{ele.display}</a> 
                    <button className="btn btn-secondary-add-cpasage tooltip1" onClick={copyToClipboard} >
                        <i className="fa fa-copy"></i>
                        <span id={ttId} className="tooltiptext1">{ttTex1}</span>
                    </button>
                </div>
                )

                function copyToClipboard(){
                    var $temp = $("<input>"); 
                    $("body").append($temp);
                    $temp.val($("#"+myid).text()).select();
                    document.execCommand("copy");
                    $temp.remove();

                    let tt = document.getElementById(ttId);
                    let tt0 = document.getElementById("tt0");
                    let tt1 = document.getElementById("tt1");
                    let tt2 = document.getElementById("tt2");
                    let tt3 = document.getElementById("tt3");

                    if(ttId == "tt0" && tt1 != null && tt2 != null && tt3 != null){
                        tt1.innerText = ttTex1;
                        tt2.innerText = ttTex1;
                        tt3.innerText = ttTex1;
                    }
                    else if(ttId == "tt1" && tt0 != null && tt2 != null && tt3 != null){
                        tt0.innerText = ttTex1;
                        tt2.innerText = ttTex1;
                        tt3.innerText = ttTex1;
                    }
                    else if(ttId == "tt2" && tt0 != null && tt1 != null && tt3 != null){
                        tt0.innerText = ttTex1;
                        tt1.innerText = ttTex1;
                        tt3.innerText = ttTex1;
                    }
                    else if(ttId == "tt3" && tt0 != null && tt1 != null && tt2 != null){
                        tt0.innerText = ttTex1;
                        tt1.innerText = ttTex1;
                        tt2.innerText = ttTex1;
                    }
                    
                    if(tt != null)
                    tt.innerText = ttTex2;
                }
            })
            return (
                <div className="contact-box">
                    <ContentBoxAdv title={"Personalized Branding Links"}
                        body={jsxBody} />
                </div>
            )
        }
    }

    function getLogoBodyContent() {
        let displayElements: JSX.Element[] = [];
        if (props.cpaLogoUrl == undefined) {
            displayElements.push(<p>No Logo Uploaded</p>);
            displayElements.push(<a href="#" onClick={() => setShowLogoForm(true)}>Add</a>);
        } else {
            displayElements.push(<p>Current Logo Uploaded: <img src={props.cpaLogoUrl} /></p>);
            displayElements.push(<a href="#" onClick={() => setShowLogoForm(true)}>Change</a>);
        }
        if (showLogoForm) {
            let form = <React.Fragment>
                <ModalFilePicker value={cpaLogoValue} onChange={(e) => {
                    if (e.target.files != undefined) {
                        setCpaLogoValue(e.target.files[0]);
                    }
                }} />
                <Button buttonValue={"Submit"} onClick={() => {
                    let formData = new FormData();
                    if (cpaLogoValue != undefined) {
                        formData.append("logo_file", cpaLogoValue);
                    }
                    const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token, { section: "upload_admin_logo" }, true) as Promise<IPostUploadCPALogo>;
                    requestResponse.then((response) => {
                        props.setCpaLogoUrl(response.data.logo_url);
                        setCpaLogoValue(undefined);
                        setShowLogoForm(false);
                    })
                }} />
                <Button buttonValue={"Cancel"} onClick={() => {
                    setCpaLogoValue(undefined);
                    setShowLogoForm(false);
                }} />
            </React.Fragment>
            displayElements.push(form);
        }
        return <div>{displayElements}</div>;
    }

    function getLogoContent() {
        return (
            <div className="contact-box" style={{ height: "47%", marginBottom: "3%" }}>
                <ContentBoxAdv title={"CPA Logo"}
                    body={getLogoBodyContent()} />
            </div>
        )
    }

    function templateTabCallback(tab: string) {
        let templateReqResp = undefined;
        let tabIndex = 0;
        if (tab == "individual") {
            if (templateData[0] == undefined) {
                templateReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "template_fetch", template_subsection: "personal" }) as Promise<IFetchTemplate>;
            }
            tabIndex = 0;
        } else if (tab == "business") {
            if (templateData[1] == undefined) {
                templateReqResp = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, { section: "template_fetch", template_subsection: "business" }) as Promise<IFetchTemplate>;
            }
            tabIndex = 1;
        }

        if (templateReqResp != undefined) {
            templateReqResp.then((response) => {
                setTemplateData((prevTemplateData) => {
                    let newTemplateData = [...prevTemplateData];
                    newTemplateData[tabIndex] = response.data;
                    return newTemplateData;
                })
            })
        }
        setTemplateCurrTab(tabIndex);
    }

    function inputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "string") {
            if (value == "deleteTemplate") {
                confirm("Are you sure you want to delete this template?").then(() => {
                    let formData = new FormData();
                    let currTable = templateData[templateCurrTab];
                    if (currTable != undefined) {
                        formData.append("template_id", currTable[index].id.toString());
                        const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token,
                            { section: "delete_template" });
                        requestResponse.then(() => {
                            setTemplateData((prevTemplateData) => {
                                let newTemplateData = [...prevTemplateData];
                                let currTable = newTemplateData[templateCurrTab];
                                if (currTable != undefined) {
                                    newTemplateData[templateCurrTab] = currTable.filter((data, loopIndex) => loopIndex != index);
                                }
                                return newTemplateData;
                            })
                        })
                    }
                })
            }
        }
    }

    function getTemplateContent() {
        let tab_config = [
            { "target": "Individual", "display": "Individual", "id": "personalTab", "active": true },
            { "target": "Business", "display": "Business", "id": "businessTab", "active": false },
        ]
        let columnName = ['FileName', 'Upload Time', 'Action']
        let accessKey = ['at_file_name', 'at_upload_time', 'actionButton']
        let columnNames = [columnName, columnName]
        let accessKeys = [accessKey, accessKey]

        return (
            <div >
                <div style={{ marginBottom: "auto", width: "100%" }}>
                    <ContentBoxTabTable title={"Organizer Templates"}
                        columnNames={columnNames}
                        tabs={tab_config} accessKeys={accessKeys}
                        contents={templateData} tabCallback={templateTabCallback} hasSearch={true}
                        inputCallback={inputCallback} hasRecords={true}
                        miscHeaders={<AddButton buttonValue={<i className="fa fa-plus"></i>}
                            onClick={() => { $('#templateUploadModal').modal('show'); }} toolTipText={"Add"}/>}
                    />
                </div>
            </div>
        )
    }

    function updateTableData(dataObj: IPostUploadTemplateQueryResponseRepr) {
        setTemplateData((prevTemplateData) => {
            let newTemplateData = [...prevTemplateData];
            let perData = newTemplateData[0]
            let bnsData = newTemplateData[1];
            if (perData != undefined) {
                newTemplateData[0] = [...perData, ...dataObj.per_data];
            }
            if (bnsData != undefined) {
                newTemplateData[1] = [...bnsData, ...dataObj.bns_data];
            }
            return newTemplateData;
        })

        if (dataObj.success != undefined) {
            setSuccess(dataObj.success);
        }

        if (dataObj.fail != undefined) {
            setFail(dataObj.fail);
        }

    }

    function updateReviewLinkTableData(data: IDataRepr) {
        setReviewLinkData((prevReviewLinkData) => {
            if (reviewIndex == undefined){
                let newReviewLinkData = [...prevReviewLinkData, data];
                return newReviewLinkData;
            } else {
                let newReviewLinkData = [...prevReviewLinkData];
                newReviewLinkData[reviewIndex] = data;
                setReviewIndex(undefined);
                setReviewLink(undefined);
                setReviewType(undefined);
                return newReviewLinkData;
                
            }
        })
    }

    function generalTabContent() {
        return (
            <React.Fragment>
                <div className="col-sm-12 tabSec pt-3">
                    <div className="contact-detail row" >
                            {getAccessContent()}
                            {getPaymentMethodAccessContent()}
                            {getReviewLinkContent()}
                        <div className="box-col col-md-6">
                            {getSignupContent()}
                        </div>
                        <div className="box-col col-md-6">
                            {getLogoContent()}
                        </div>
                        <div className="box-col col-md-6">
                            {getSocialMediaContent()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    function templatesTabContent() {
        return (
            <React.Fragment>
                {getTemplateContent()}
            </React.Fragment>
        )
    }

    function integrationsTabContent() {
        return (
            <div className="col-sm-12 tabSec pt-3">
                <div className="contact-detail row" >
                    {getGoogleContent()}
                </div>
                <div className="contact-detail row" >
                    {getStripeContent()}
                </div>
            </div>
        )
    }
    function billingAccruedCharge() {
        if (billingInfo != undefined) {
            return (
                <div style={{marginLeft: "5%",marginTop: "4%", color:"#4a4a4a"}}>
                    {/* <h5 style={{display: "inline"}}>Total: {billingInfo.accrued_charge.total}</h5> */}
                    <span style={{float: "right"}}>Days left till next payment: <b>{billingInfo.accrued_charge.days_left} Days</b></span>
                    <table className="table">
                        <thead>
                            <th>No</th>
                            <th>Service</th>
                            <th>Qty</th>
                            <th>Amount</th>
                        </thead>
                        <tbody>
                            <tr>
                            <td>1.</td>
                            <td>Clients:</td>
                            <td>{billingInfo.accrued_charge.client.num}</td>
                            <td>{billingInfo.accrued_charge.client.charge}</td>
                            
                            </tr>
                            <tr>
                            <td>2.</td>
                            <td>Business:</td>
                            <td>{billingInfo.accrued_charge.business.num}</td>
                            <td>{billingInfo.accrued_charge.business.charge}</td>
                            
                            </tr>
                            <tr>
                            <td>3.</td>
                            <td>Esign Documents:</td>
                            <td>{billingInfo.accrued_charge.esign.num}</td>
                            <td>{billingInfo.accrued_charge.esign.charge}</td>

                            </tr>
                            <tr>
                            <td>4.</td>
                            <td>Bank Account Verified:</td>
                            <td>{billingInfo.accrued_charge.bank_account_verify.num}</td>
                            <td>{billingInfo.accrued_charge.bank_account_verify.charge}</td>
                            
                            </tr>
                            <tr>
                            <td></td>
                            <td><b>Total:</b></td>
                            <td></td>
                            <td>{billingInfo.accrued_charge.total}$</td>
                            </tr>
                            
                        </tbody>
                        
                    </table>
                    {/* <ul style={{marginLeft:"4.5%", marginTop:"1%"}}>
                        <li>{billingInfo.accrued_charge.client.num} Clients: {billingInfo.accrued_charge.client.charge}</li>
                        <li>{billingInfo.accrued_charge.business.num} Business: {billingInfo.accrued_charge.business.charge}</li>
                        <li>{billingInfo.accrued_charge.esign.num} Esign Documents: {billingInfo.accrued_charge.esign.charge}</li>
                    </ul> */}
                </div>
            )
        }
        return <div></div>
    }

    function billingContact() {
        if (billingInfo != undefined) {
            if (billingInfo.billing_contact.is_set && billingInfo.billing_contact.address != undefined) {
                return (
                    <div style={{marginLeft: "5%",marginTop: "4%", color:"#4a4a4a"}}> 
                        <span>{billingInfo.billing_contact.address.line1}</span><br />
                        <span>{billingInfo.billing_contact.address.city}, <br />{billingInfo.billing_contact.address.state},<br /> {billingInfo.billing_contact.address.country} {billingInfo.billing_contact.address.postal_code}</span>
                    </div>
                )
            } else {
                return <p><a href="#" onClick={() => $('#billingContactModal').modal('show')}>Add</a> a Billing Contact Address</p>
            }
        }
        return <div></div>
    }

    function billingContactOnSuccess(address: IAddressRepr) {
        setBillingInfo((prevBillingInfo) => {
            if (prevBillingInfo != undefined) {
                let newBillingInfo = { ...prevBillingInfo };
                if (newBillingInfo.billing_contact != undefined) {
                    newBillingInfo.billing_contact.address = address;
                    newBillingInfo.billing_contact.is_set = true;
                }
                return newBillingInfo;
            }
        })
    }

    function paymentMethods() {
        if (billingInfo != undefined) {
            if (billingInfo.payment_methods.length > 0) {
                return (
                    <React.Fragment>
                    {/* <a href="#" onClick={() => $('#paymentMethodModal').modal('show')}>Add</a> */}
                    <div style={{marginTop: "4%", color:"#4a4a4a"}}>
                        {billingInfo.payment_methods.map((paymentMethod) => {
                            if (isCard(paymentMethod)) {
                                return (
                                    <div className="row" style={{ border: "1px solid #d9d9d9", padding: "8px", margin: "20px", justifyContent: "center" }}>
                                        <div className="col-2">
                                            {(() => {
                                                let className = "m-o p-0"
                                                if (paymentMethod.brand == "visa") {
                                                    return <img className={className} src={visa} alt="" />
                                                } else if (paymentMethod.brand == "mastercard") {
                                                    return <img className={className} src={mastercard} alt="" />
                                                } else if (paymentMethod.brand == "discover") {
                                                    return <img className={className} src={discover} alt="" />
                                                } else if (paymentMethod.brand == "amex") {
                                                    return <img className={className} src={americanExpress} alt="" />
                                                } else if (paymentMethod.brand == "unionpay") {
                                                    return <img className={className} src={unionPay} alt="" />
                                                } else if (paymentMethod.brand == "diners") {
                                                    return <img className={className} src={dinersClub} alt="" />
                                                } else if (paymentMethod.brand == "jcb") {
                                                    return <img className={className} src={jcb} alt="" />
                                                } else {
                                                    return <img className={className} src={creditCard} alt="" />
                                                }
                                            })()}
                                        </div>
                                        <div className="col-6">
                                            XXXX-XXXX-XXXX-{paymentMethod.last4}
                                        </div>
                                        <div className="col-4">
                                            Expiry: &nbsp;
                                            {paymentMethod.exp_month}/{paymentMethod.exp_year}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    </React.Fragment>
                )
            } else {
                return <p>No Payment Methods Added
                    <a href="#" onClick={() => $('#paymentMethodModal').modal('show')}>Add</a>
                </p>
            }
        }
        return <div></div>
    }

    function paymentMethodOnSuccess(paymentMethod: IPaymentMethodRepr) {
        setBillingInfo((prevBillingInfo) => {
            if (prevBillingInfo != undefined) {
                let newBillingInfo = { ...prevBillingInfo };
                newBillingInfo.payment_methods.push(paymentMethod);
                return newBillingInfo;
            }
        })
    }

    function billingHistoryInputCallback(index: number, accessKey: string, value?: boolean | string | HTMLCollectionOf<HTMLOptionElement>) {
        if (typeof value == "string") {
            if (value == "pay_now") {
                if (billingInfo != undefined) {
                    let queryParams = {
                        section: "create_charge_now"
                    }
                    let formData = new FormData();
                    formData.append("admin_billing_id", billingInfo.billing_history[index].id.toString());
                    const requestResponse = post(BILLING_ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IPostCreateCharge>;
                    requestResponse.then((response) => {
                        setBillingInfo((prevBillingInfo) => {
                            let newBillingInfo = cloneDeep(prevBillingInfo);
                            if (newBillingInfo != undefined) {
                                if (newBillingInfo.billing_history != undefined) {
                                    newBillingInfo.billing_history[index] = response.data.data;
                                }
                            }
                            return newBillingInfo;
                        })
                    })
                }
            }
        }
    }

    function billingTabContent() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6 col-sm-12 p-3">
                        <div className="contact-box" style={{alignItems:"start"}}>
                            <ContentBoxAdv title={"Account Balance"} body={<div></div>} hStyle={{borderBottom: "1px solid rgb(167 167 167)"}}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 p-3">
                        <div className="contact-box" style={{alignItems:"start"}}>
                            {(()=>{
                                if (billingInfo?.billing_contact.is_set && billingInfo?.billing_contact.address == undefined){
                                    return(                   
                                        <ContentBoxAdv title={"Billing Contact"} body={billingContact()} callBack={() => $('#billingContactModal').modal('show')} hStyle={{borderBottom: "1px solid rgb(167 167 167)"}}/>
                                        )
                                }
                                else{
                                    return(                   
                                    <ContentBoxAdv title={"Billing Contact"} body={billingContact()} hStyle={{borderBottom: "1px solid rgb(167 167 167)"}}/>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 p-3">
                        <div className="contact-box" style={{alignItems:"start"}}>
                        <ContentBoxAdv title={"Payment Methods"} body={paymentMethods()} buttonValue={<i className="fa fa-plus"></i>} 
                            callBack={() => $('#paymentMethodModal').modal('show')} hStyle={{borderBottom: "1px solid rgb(167 167 167)"}}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 p-3 ">
                        <div className="contact-box" style={{alignItems:"start"}}>
                            <ContentBoxAdv title={"Accrued Charges"} body={billingAccruedCharge()}
                                hStyle={{borderBottom: "1px solid rgb(167 167 167)"}}/>
                        </div>
                    </div>
                </div>
                <div className="p-3">
                <div className="contact-box p-3" style={{alignItems:"start", display:"block"}}>
                    <ContentBoxTable title={"Billing History"} columnNames={["ID", "Description", "Date", "Amount", "Payment", "Action"]}
                        accessKeys={["id", "ab_description", "ab_date", "ab_amount", "ab_payment", "actionButton"]} hasSearch={false} hasRecords={false} tableData={billingInfo?.billing_history}
                        inputCallback={billingHistoryInputCallback} />
                </div>
                </div>
            </React.Fragment>
        )
    }

    function fetchBillingInfo() {
        let queryParams = {
            section: "billing_info"
        }
        const requestResponse = get(BILLING_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchBillingInformation>
        requestResponse.then((response) => {
            setBillingInfo(response.data.data);
        })
    }

    function outerTabCallback(tabName: string, index: number) {
        if (tabName == "billing" && billingInfo == undefined) {
            fetchBillingInfo();
        }
    }

    function getTab() {
        let content = [{ content: generalTabContent() },
        { content: templatesTabContent() },
        { content: integrationsTabContent() },
        { content: billingTabContent()}];
        return <Tabs tabs={tabConfig} contents={content} tabCallback={outerTabCallback} />
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getTab()}
                <ModalReviewLinkAdd reviewIndex={reviewIndex} reviewLink={reviewLink} reviewType={reviewType} mutator={updateReviewLinkTableData} />
                <ModalUploadTemplate mutator={updateTableData} />
                <ModalBillingContact onSuccess={billingContactOnSuccess} />
                <ModalPaymentMethod onSuccess={paymentMethodOnSuccess} 
                    onFail={(fail) => {}} addressObj={billingInfo?.billing_contact.address} />
            </div >
        )
    }
}


export default AdminSettings;
