import React  from 'react';
import ReactDOM from "react-dom";
import { AxiosResponse } from 'axios';
import $ from "jquery";

import ModalDropdown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalFilePicker from "../../../intelws_portal/constructs/modal/ModalFilePicker";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";
import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";

import { IPostUploadTemplateQueryResponseRepr } from "../admin/AdminSettings";
import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from '../../../declarations/common_utils';

const SETTINGS_ENDPOINT = "api/settings/";

export interface IModalUploadTemplateProps {
    mutator: (queryResponse: IPostUploadTemplateQueryResponseRepr) => void
}

interface IFetchCatConfig {
    uploadpersonal: number,
    uploadbusiness: number
}

export interface IFetchUploadTemplateConfig extends AxiosResponse {
    data: {
        cat_config: IFetchCatConfig
    }
}

export interface IPostUploadTemplate extends AxiosResponse {
    data: {
        query_response: IPostUploadTemplateQueryResponseRepr
    }
}

function ModalUploadTemplate(props: IModalUploadTemplateProps) {
    const [catConfig, setCatConfig] = React.useState<IFetchCatConfig>({ uploadpersonal: -1, uploadbusiness: -1 });
    const [catId, setCatId] = React.useState(-1);
    const [file, setFile] = React.useState<(File | undefined)[]>([undefined]);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {section: "upload_template_config"};
        const requestResponse = get(SETTINGS_ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchUploadTemplateConfig>
        requestResponse.then((response) => {
            setCatConfig(response.data.cat_config);
            setCatId(response.data.cat_config.uploadpersonal);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }

    }, [])

    React.useEffect(() => {
        $("#templateUploadModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#templateUploadModal").off("hidden.bs.modal");
        }
    }, [catConfig])

    function clearForm() {
        setCatId(catConfig.uploadpersonal);
        setFile([undefined]);
    }

    function getFiles() {
        return file.map((ele, index) => {
            return (
                <ModalFilePicker onChange={(e) => formFieldChange(e, "file", index)} value={ele} key={index} />
            )
        })
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, section: "file" | "catType", index?: number) {
        if (section == "file" && index != undefined) {
            setFile((prevFile) => {
                let newFile = [...prevFile];
                let fileEvent = (e as React.ChangeEvent<HTMLInputElement>);
                if (fileEvent.target.files != null) {
                    newFile[index] = fileEvent.target.files[0];
                } else {
                    newFile[index] = undefined;
                }
                return newFile;
            })
        } else {
            setCatId(parseInt(e.target.value));
        }
    }

    function saveCallback() {
        let formData = new FormData();
        formData.append("cat", catId.toString());
        file.forEach((ele) => {
            if (ele != undefined) {
                formData.append("file", ele)
            }
        })
        const requestResponse = post(SETTINGS_ENDPOINT, formData, cancelTokenSource.current.token, { section: "upload_template_add" }, true) as Promise<IPostUploadTemplate>;
        requestResponse.then((response) => {
            props.mutator(response.data.query_response);
            $("#templateUploadModal").modal("hide");
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator({per_data: [], bns_data: [], success: success, fail: fail});
            $("#templateUploadModal").modal("hide");
        })
    }

    function addFileCallback() {
        setFile((prevFile) => {
            let newFile = [...prevFile, undefined];
            return newFile;
        })
    }

    function getContent() {
        return (
            <React.Fragment>
                <ModalDropdown value={catId}
                    options={["Individual", "Business"]}
                    values={[catConfig.uploadpersonal, catConfig.uploadbusiness]}
                    onChange={(e) => formFieldChange(e, "catType")} borderBottom/>
                {getFiles()}
            </React.Fragment>
        )
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="templateUploadModal"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Documents"} />
                        <div className="modal-body">
                            {getContent()}
                        </div>
                        <ModalFooter saveCallback={saveCallback} saveInactive={false} 
                            buttonConfig={[{ buttonName: "Add File", callback: addFileCallback, inactive: false }]}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalUploadTemplate;