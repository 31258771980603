import React, { Component } from 'react';
import ReactDOM from "react-dom";
import $ from "jquery";
import { AxiosResponse } from 'axios';

import ContextBoxTable from "../../../intelws_portal/bundles/ContentBoxTable";
import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";

interface IFetchHistoryStatus extends AxiosResponse {
    data: {
        column_names: string[],
        access_keys: string[],
        data: IDataRepr[],
        title: string
    }
}

interface IModalHistoryStatusProps {
    userId: number,
    trackingId?: number,
    closeCallback: () => void
}

function ModalHistoryStatus(props: IModalHistoryStatusProps) {
    const ENDPOINT = "api/clientdashboard/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [columnNames, setColumnNames] = React.useState<string[]>([]);
    const [accessKeys, setAccessKeys] = React.useState<string[]>([]);
    const [tableData, setTableData] = React.useState<IDataRepr[]>([]);
    const [title, setTitle] = React.useState("");

    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        $("#historyStatusModal").on("hidden.bs.modal", props.closeCallback);
        return () => {
            cancelTokenSource.current.cancel();
            $("#historyStatusModal").off("hidden.bs.modal");
        }
    }, [])

    React.useEffect(() => {
        setIsLoaded(false);
        if (props.trackingId != undefined) {
            $("#historyStatusModal").modal("show")
            let queryParams = {
                user_id: props.userId,
                section: "history_status",
                tracking_id: props.trackingId
            }
            const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchHistoryStatus>;
            requestResponse.then((response) => {
                setColumnNames(response.data.column_names);
                setAccessKeys(response.data.access_keys);
                setTableData(response.data.data);
                setTitle(response.data.title)
                setIsLoaded(true);
            })
        }
    }, [props.trackingId])

    function getModalContent() {
        if (!isLoaded) {
            return <Loading />;
        } else {
            return (
                <React.Fragment>
                    <ModalHeader title={title} />
                    <div className="modal-body">
                        <div className="tableFixHead table-responsive custom-table">
                            <ContextBoxTable tableData={tableData} accessKeys={accessKeys}
                                columnNames={columnNames} title="" hasSearch={false} />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="historyStatusModal" tabIndex={-1}>
                <div className="modal-dialog text-left modal-lg">
                    <div className="modal-content">
                        {getModalContent()}
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById("modal") as Element);
}

export default ModalHistoryStatus;
