import { AxiosResponse } from "axios";
import React from "react";
import { getBundledAlertsUI, parseQueryResponseOnFail, parseQueryResponseOnFailResponse } from "../../../declarations/common_utils";
import Alert from "../../../intelws_portal/constructs/elements/Alert";
import Loading from "../../../intelws_portal/constructs/elements/Loading";
import ModalInputText from "../../../intelws_portal/constructs/modal/ModalInputText";
import { get, getCancelToken, post } from "../../../intelws_portal/utils/backendInterface";

interface IFetchClientProfileConfig extends AxiosResponse {
    data: {
        data: {
            first_name: string,
            last_name: string,
            email: string,
            phone_number: string,
            is_verify_email: boolean,
            new_email: string
        }
    }
}

interface IClientSettingsProps {
    mutator: {
        currentPage: (currentPage: string) => void
    }
}

function ClientSettings(props: IClientSettingsProps) {
    const ENDPOINT = "/api/userprofile/";
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [isVerifyEmail, setIsVerifyEmail] = React.useState(false);
    const [isShowVerifyEmailForm, setIsShowVerifyEmailForm] = React.useState(false);

    //Changing account details
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [newEmail, setNewEmail] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [editErrorObj, setEditErrorObj] = React.useState(getEmptyEditErrorObj);

    //Verifying new email
    const [code, setCode] = React.useState("");
    const [codeErrorObj, setCodeErrorObj] = React.useState(getEmptyCodeErrorObj);
    
    const [success, setSuccess] = React.useState<string[]>([]);
    const [fail, setFail] = React.useState<string[]>([]);

    const cancelTokenSource = React.useRef(getCancelToken());

    function getEmptyEditErrorObj() {
        return {
            first_name: {
                isError: false,
                errorText: ""
            },
            last_name: {
                isError: false,
                errorText: ""
            },
            email: {
                isError: false,
                errorText: ""
            },
            phone_number: {
                isError: false,
                errorText: ""
            },
        }
    }

    function getEmptyCodeErrorObj() {
        return {
            code: {
                isError: false,
                errorText: ""
            }
        }
    }

    React.useEffect(() => {
        props.mutator.currentPage("ClientSettings");
        let queryParams = {
            section: "user_profile_config"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchClientProfileConfig>;
        requestResponse.then((response) => {
            setFirstName(response.data.data.first_name);
            setLastName(response.data.data.last_name);
            setEmail(response.data.data.email);
            setPhoneNumber(response.data.data.phone_number);
            setIsVerifyEmail(response.data.data.is_verify_email);
            setNewEmail(response.data.data.new_email);
            setIsLoaded(true);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            setSuccess(success);
            setFail(fail);
        })
    }, [])

    async function accountDetailsFormSubmit() {
        let queryParams = {
            section: "user_profile_edit"
        }
        let formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("phone_number", phoneNumber);

        try {
            const response = await post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as IFetchClientProfileConfig;
            setFirstName(response.data.data.first_name);
            setLastName(response.data.data.last_name);
            setEmail(response.data.data.email);
            setPhoneNumber(response.data.data.phone_number);
            setIsVerifyEmail(response.data.data.is_verify_email);
            setNewEmail(response.data.data.new_email);
            setEditErrorObj(getEmptyEditErrorObj);
            setIsProcessing(false);
            return false;
        } catch(error) {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            setSuccess(success);
            setFail(fail);
            let errorObj = getEmptyEditErrorObj();
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setEditErrorObj(errorObj);
            setIsProcessing(false);
            return true;
        }

       
    }

    function verifyEmailFormSubmit() {
        let queryParams = {
            section: "user_profile_verify_email"
        }
        let formData = new FormData();
        formData.append("code", code);
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams) as Promise<IFetchClientProfileConfig>;
        requestResponse.then((response) => {
            setFirstName(response.data.data.first_name);
            setLastName(response.data.data.last_name);
            setEmail(response.data.data.email);
            setPhoneNumber(response.data.data.phone_number);
            setIsVerifyEmail(response.data.data.is_verify_email);
            setNewEmail(response.data.data.new_email);
            setCode("");
            setIsShowVerifyEmailForm(false);
            setCodeErrorObj(getEmptyCodeErrorObj);
        }).catch((error) => {
            let [success, fail, failResponse] = parseQueryResponseOnFail(error);
            setSuccess(success);
            setFail(fail);
            let errorObj = getEmptyCodeErrorObj();
            parseQueryResponseOnFailResponse(errorObj, failResponse);
            setCodeErrorObj(errorObj);
        })
    }

    function formChange(e?: React.ChangeEvent<HTMLInputElement>, section?: "firstName" | "lastName" | "email" | "phoneNumber" | "code" | "toggleEdit" | "toggleEmailVerifyForm") {
        if (section != undefined) {
            if (e != undefined) {
                if (section == "firstName") {
                    setFirstName(e.target.value);
                } else if (section == "lastName") {
                    setLastName(e.target.value);
                } else if (section == "email") {
                    setEmail(e.target.value);
                } else if (section == "phoneNumber") {
                    setPhoneNumber(e.target.value);
                } else if (section == "code") {
                    setCode(e.target.value);
                }
            } else {
                if (section == "toggleEdit") {
                    if (isEdit) {
                        setIsProcessing(true);
                        accountDetailsFormSubmit().then((newStateValue) => {
                            setIsEdit(newStateValue);
                        })
                    } else {
                        setIsEdit(true);
                    }
                } else if (section == "toggleEmailVerifyForm") {
                    setIsShowVerifyEmailForm((prevIsShowVerifyEmailForm) => {
                        return !prevIsShowVerifyEmailForm;
                    })
                }
            }
        }
    }


    function getUserDetailsContent() {
        return (
            <div className="card position-relative">
                <div className="card-body">
                    <div className="card-title">
                        Account Details
                        {(() => {
                            if (isProcessing) {
                                return (
                                    <button className="btn btn-secondary-add-cpasage float-right" > Saving...</button>
                                )
                            }
                            if (isEdit) {
                                return (
                                    <button className="btn btn-secondary-add-cpasage float-right" onClick={(e) => formChange(undefined, "toggleEdit")}> Update</button>
                                )
                            } else {
                                return (
                                    <button className="btn btn-secondary-add-cpasage float-right" onClick={(e) => formChange(undefined, "toggleEdit")}> Edit</button>
                                )
                            }
                        })()}
                    </div>
                    {(() => {
                        if (isVerifyEmail) {
                            return (
                                <div className="row">
                                    <div className="alert alert-dark" style={{width: undefined, backgroundColor: "#4b5ca9c5", borderLeft: undefined, color: "white", boxShadow: "0 0 0px 0px #ccc"}}>
                                        You changed your email to {newEmail}. Please <a href="#" className="alert-link" style={{color:"white", fontSize:"18px"}} onClick={(e) => formChange(undefined, "toggleEmailVerifyForm")}>verify here</a> your new email by entering the code sent to {newEmail}.
                                        {(() => {
                                            if (isShowVerifyEmailForm) {
                                                return (
                                                    <div className="row" style={{marginTop: "10px"}}>
                                                        <div className="col-6">
                                                            <ModalInputText inputType="number" title="Code" value={code} 
                                                                onChange={(e) => formChange(e, "code")} isError={codeErrorObj.code.isError} errorText={codeErrorObj.code.errorText} />
                                                        </div>
                                                        <div className="col-6" >
                                                        <button className="btn btn-secondary-add-cpasage mt-0" onClick={verifyEmailFormSubmit}> Verify</button>
                                                        </div>
                                                    </div>
                                                ) 
                                            }
                                        })()}
                                    </div>
                                </div>
                            )
                        }
                    })()}
                    <div className="row">
                        <div className="col-2">
                            <p className="font-weight-bold" style={{marginTop: "10px", marginBottom:"0px"}}>First Name:</p>
                        </div>
                        <div className="col-8">
                            <ModalInputText title="First Name" value={firstName} borderBottom onChange={(e) => formChange(e, "firstName")}
                                disabled={!isEdit} isError={editErrorObj.first_name.isError} errorText={editErrorObj.first_name.errorText} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <p className="font-weight-bold" style={{marginTop: "10px", marginBottom:"0px"}}>Last Name:</p>
                        </div>
                        <div className="col-8">
                            <ModalInputText title="Last Name" value={lastName} borderBottom onChange={(e) => formChange(e, "lastName")}
                                disabled={!isEdit} isError={editErrorObj.last_name.isError} errorText={editErrorObj.last_name.errorText} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <p className="font-weight-bold" style={{marginTop: "10px", marginBottom:"0px"}}>Email:</p>
                        </div>
                        <div className="col-8">
                            <ModalInputText title="Email" value={email} borderBottom onChange={(e) => formChange(e, "email")}
                                disabled={!isEdit} isError={editErrorObj.email.isError} errorText={editErrorObj.email.errorText} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <p className="font-weight-bold" style={{marginTop: "10px", marginBottom:"0px"}}>Phone #:</p>
                        </div>
                        <div className="col-8">
                            <ModalInputText title="Phone #" value={phoneNumber} borderBottom onChange={(e) => formChange(e, "phoneNumber")}
                                disabled={!isEdit} isError={editErrorObj.phone_number.isError} errorText={editErrorObj.phone_number.errorText} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function getContent() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        {getUserDetailsContent()}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    if (!isLoaded) {
        return <Loading />
    } else {
        return (
            <div className="body-content-wrapper clearfix">
                {getBundledAlertsUI(success, fail, setSuccess, setFail)}
                {getContent()}
            </div>
        )
    }
}

export default ClientSettings;