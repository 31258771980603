import React from 'react';
import { Link } from 'react-router-dom';
import * as urls from "../../../../Urls";

import downloadIcon from '../../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-02.svg';
import documentIcon from '../../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-03.svg';
import payIcon from '../../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-04.svg';
import taxIcon from '../../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-05.svg';
import efileIcon from '../../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-06.svg';
import efilereturnIcon from '../../../../../external/backPages/img/images/svg icons/client_dashboard_svg_icons/CPASAGE_Client home page icons-07.svg';

function ClientOnboard() {
    return (
        <div className="container-fluid bottom-sec clearfix">
             <div className="container-fluid bottom-sec clearfix">
                 <div className="heading-box">
                     <h6 className="col-sm-12"> Quick Action Links </h6>
                 </div>
                 <div className="col-sm-12 tabSec">
 
                     {/* <div className="row" > */}
                     <section className="page-contain" style={{border: "none"}}>
 
                         <div className="row">
                             <div className="col-2">
                             <a href="#" className="data-card">
                             <Link to={urls.CLIENT_ORGANIZER}>
                            
                                 <div className="" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <h4 style={{textAlign: "center"}}><img src={downloadIcon} className="active" alt="" style={{width:"30%"}}/></h4>
                                     </span>
                                     <h4 style={{textAlign: "center"}}> Organizer </h4>
                                     
                                     </div>
                                 </div>
                             
                             </Link>
                             </a>
                             </div>
 
                             <div className="col-2">
                             <a href="#" className="data-card">
                             <Link to={urls.CLIENT_DOCUMENT} >
                            
                                 <div className="" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <h4 style={{textAlign: "center"}}><img src={documentIcon} className="active" alt="" style={{width:"30%"}}/></h4>
                                     </span>
                                     <h4 style={{textAlign: "center"}}> Tax Docs </h4>
                                     
                                     </div>
                                 </div>
                             
                             </Link>
                             </a>
                             </div>
 
                             <div className="col-2">
                             <a href="#" className="data-card">
                             <Link to={urls.CLIENT_INVOICE}> 
                            
                                 <div className="" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <h4 style={{textAlign: "center"}}><img src={payIcon} className="active" alt="" style={{width:"30%"}}/></h4>
                                     </span>
                                     <h4 style={{textAlign: "center"}}>  Payment  </h4>
                                     
                                     </div>
                                 </div>
                             
                             </Link>
                             </a>
                             </div>
 
                             <div className="col-2">
                             <a href="#" className="data-card">
                             <Link to={urls.CLIENT_DOCUMENT}>
                            
                                 <div className="" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <h4 style={{textAlign: "center"}}><img src={taxIcon} className="active" alt="" style={{width:"30%"}}/></h4>
                                     </span>
                                     <h4 style={{textAlign: "center"}}> Tax Return </h4>
                                     
                                     </div>
                                 </div>
                             
                             </Link>
                             </a>
                             </div>
 
                             <div className="col-2">
                             <a href="#" className="data-card">
                             <Link to={urls.CLIENT_DOCUMENT} >
                            
                                 <div className="" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <h4 style={{textAlign: "center"}}><img src={efileIcon} className="active" alt="" style={{width:"30%"}}/></h4>
                                     </span>
                                     <h4 style={{textAlign: "center"}}> E-Sign</h4>
                                     
                                     </div>
                                 </div>
                             
                             </Link>
                             
                             </a>
                             </div>
 
                             <div className="col-2">
                             <a href="#" className="data-card">
                             <Link to={urls.CLIENT_STATUS} >
                            
                                 <div className="" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <h4 style={{textAlign: "center"}}><img src={efilereturnIcon} className="active" alt="" style={{width:"30%"}}/></h4>
                                     </span>
                                     <h4 style={{textAlign: "center"}}> Status </h4>
                                     
                                     </div>
                                 </div>
                             
                             </Link>
                            
                             </a>
                             </div>
 
                             </div>
 
 
 
                             
                         
                        
                        
 
                     </section>
                         {/* <Link to={urls.CLIENT_ORGANIZER} className="box-col col-md-2 col-sm-4 col-xs-6">
                            
                                 <div className="contact-box" style={{ flexDirection: 'column' }}>
                                     <div className="onbordingcard">
                                     <span> <br />
                                         <i className="fa fa-file-text-o" ></i>
                                     </span>
                                     <h6> Download </h6>
                                     <span className="fa fa-question-circle" data-toggle="tooltip"
                                         title="Navigate to 'Organizer' tab from the top menu to download the applicable organizer(s)">
                                     </span>
                                     </div>
                                 </div>
                             
                         </Link>
                         <Link to={urls.CLIENT_DOCUMENT} className="box-col col-md-2 col-sm-4 col-xs-6">
                                 <div className="contact-box" style={{ flexDirection: 'column' }}>
                                     <span ><br />
                                         <i className="fa fa-upload "></i>
                                     </span>
                                     <h6> Upload </h6>
                                     <span className="fa fa-question-circle" data-togle="tooltip"
                                         title="Navigate to 'Document(s)' tab from the top menu to upload all the tax documents required for tax filing and 
                                  check the upload complete checkbox located under Active Engagement(s) option in the dashboard">
                                     </span>
                                 </div>
                         </Link>
                         <Link to={urls.CLIENT_DASHBOARD} className="box-col col-md-2 col-sm-4 col-xs-6">
                                 <div className="contact-box"
                                     style={{ flexDirection: 'column' }}>
                                     <span > <br />
                                         <i className="fa fa-dollar"></i>
                                     </span>
                                     <h6> Sign &amp; Pay </h6>
                                     <span className="fa fa-question-circle" data-toggle="tooltip"
                                         title="E-sign the Engagement letter and pay the Invoice sent to your email with payment option">
                                     </span>
                                 </div>
                         </Link>
                         <Link to={urls.CLIENT_DOCUMENT} className="box-col col-md-2 col-sm-4 col-xs-6">
                                 <div className="contact-box" style={{ flexDirection: 'column'}}>
                                     <span ><br />
                                         <i className="fa fa-book"></i>
                                     </span>
                                     <h6 >Tax Return</h6>
                                     <span className="fa fa-question-circle" data-togle="tooltip"
                                         title="Navigate to 'Documents' tab and review the Tax Return uploaded under 'Tax Office Uploaded' 
                                            tab and send us your questions via chat message located at the bottom of the 'Dashboad' page">
                                     </span>
                                 </div>
                         </Link>
                         <Link to={urls.CLIENT_DOCUMENT} className="box-col col-md-2 col-sm-4 col-xs-6">
                                 <div className="contact-box"
                                     style={{ flexDirection: 'column'}}>
                                     <span> <br />
                                         <i className="fa fa-files-o "></i>
                                     </span>
                                     <h6> E-file Auth </h6>
                                     <span className="fa fa-question-circle" data-toggle="tooltip"
                                         title="E-Sign the E-file Authorization to give us permission to Efile the return">
                                     </span>
                                 </div>
                         </Link>
                         <Link to={urls.CLIENT_STATUS} className="box-col col-md-2 col-sm-4 col-xs-6">
                                 <div className="contact-box" style={{ flexDirection: 'column'}}>
                                     <span><br />
                                         <i className="fa fa-check-square-o"></i>
                                     </span>
                                     <h6> Efile Return </h6>
                                     <span className="fa fa-question-circle" data-toggle="tooltip"
                                         title="Tax Return has been succefully Efiled and the E file acceptance letter will be uploaded 
                                             after we have received from tax agency">
                                     </span>
                                 </div>
                         </Link> */}
                     {/* </div> */}
                 </div>
             </div>
 
         </div>
    )
}

export default ClientOnboard;