import { AxiosResponse } from "axios";
import React from "react";
import $ from "jquery";
import ReactDOM from "react-dom";

import { IDataRepr } from "../../../intelws_portal/constructs/elements/Table";
import { get, post, getCancelToken } from "../../../intelws_portal/utils/backendInterface";
import ModalDropDown from "../../../intelws_portal/constructs/modal/ModalDropDown";
import ModalFilePicker from "../../../intelws_portal/constructs/modal/ModalFilePicker";
import ModalFooter from "../../../intelws_portal/constructs/modal/ModalFooter";
import ModalHeader from "../../../intelws_portal/constructs/modal/ModalHeader";

import { parseQueryResponseOnFail, parseQueryResponseOnSuccess } from "../../../declarations/common_utils";
import ModalCheckbox from "../../../intelws_portal/constructs/modal/ModalCheckbox";

interface IManualDocumentsConfigRepr {
    access_options: string[],
    access_values: number[],
    is_user: boolean
}

interface IFetchManualDocumentsAddConfig extends AxiosResponse {
    data: IManualDocumentsConfigRepr
}

interface IPostManualDocumentsAdd extends AxiosResponse {
    data: {
        data: IDataRepr[],
        access: string
    }
}

export interface IModalManualTaskUploadProps {
    userId: number,
    taskId: number,
    mutator: (data?: IDataRepr[], access?: string, success?: string[], fail?: string[]) => void
}

function ModalManualTaskUpload(props: IModalManualTaskUploadProps) {
    const ENDPOINT = "/api/manualtasklist/";
    const [config, setConfig] = React.useState<IManualDocumentsConfigRepr | undefined>();

    const [accessValue, setAccessValue] = React.useState(-1);
    const [isLock, setIsLock] = React.useState(false);
    const [files, setFiles] = React.useState<(File | undefined)[]>([undefined]);
    const [inTransit, setInTransit] = React.useState(false);
    const cancelTokenSource = React.useRef(getCancelToken());

    React.useEffect(() => {
        let queryParams = {
            section: "manual_documents_add_config"
        }
        const requestResponse = get(ENDPOINT, cancelTokenSource.current.token, queryParams) as Promise<IFetchManualDocumentsAddConfig>;
        requestResponse.then((response) => {
            setConfig(response.data);
            setAccessValue(response.data.access_values[0]);
        })
        return () => {
            cancelTokenSource.current.cancel();
        }
    }, [])

    React.useEffect(() => {
        $("#manualTaskUploadModal").on("hidden.bs.modal", clearForm)
        return () => {
            $("#manualTaskUploadModal").off("hidden.bs.modal");
        }
    }, [config])

    function clearForm() {
        if (config != undefined) {
            setAccessValue(config.access_values[0]);
        }
        setFiles([undefined]);
        setInTransit(false);
    }

    function formFieldChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, section: "access" | "file" | "lock", index?: number) {
        if (section == "access") {
            setAccessValue(parseInt(e.target.value));
            setIsLock(false);
        } else if (section == "file" && index != undefined) {
            setFiles((prevFiles) => {
                let newFiles = [...prevFiles];
                let newFileList = (e as React.ChangeEvent<HTMLInputElement>).target.files;
                if (newFileList != undefined) {
                    newFiles[index] = newFileList[0];
                }
                return newFiles;
            })
        } else if (section == "lock") {
            setIsLock((e as React.ChangeEvent<HTMLInputElement>).target.checked);
        }
    }

    function submitForm() {
        let queryParams = {
            section: "add_manual_task_documents",
            user_id: props.userId,
            task_tracking_id: props.taskId,
        }
        let formData = new FormData();
        formData.append("access", accessValue.toString());
        formData.append("lock", JSON.stringify(isLock));
        if (files.length > 0) {
            files.forEach((ele) => {
                if (ele != undefined) {
                    formData.append("file", ele);
                }
            })
        }
        const requestResponse = post(ENDPOINT, formData, cancelTokenSource.current.token, queryParams, true) as Promise<IPostManualDocumentsAdd>;
        requestResponse.then((response) => {
            let [success, fail] = parseQueryResponseOnSuccess(response);
            props.mutator(response.data.data, response.data.access, success, fail);
        }).catch((error) => {
            let [success, fail] = parseQueryResponseOnFail(error);
            props.mutator(undefined, undefined, success, fail);
        })
            .finally(() => {
                $('#manualTaskUploadModal').modal("hide");
            })
        setInTransit(true);
    }

    function getAccessDropdown() {
        if (config != undefined && config.access_values.length > 1) {
            return <ModalDropDown options={config.access_options} values={config.access_values}
                value={accessValue} onChange={(e) => formFieldChange(e, "access")} borderBottom />
        }
    }

    function getLockFileCheckbox() {
        if (accessValue == 1) {
            return <ModalCheckbox checked={isLock} label={"Lock File(s)"} checkboxCallback={(e) => formFieldChange(e, "lock")} lableCss={isLock ? { color: "red" } : { color: "green" }} />
        }
    }

    function getFiles() {
        if (files.length > 0) {
            return files.map((ele, index) => {
                return <ModalFilePicker key={index} onChange={(e) => formFieldChange(e, "file", index)} value={ele} />
            })
        }
    }

    function addFileCallback() {
        setFiles((prevFiles) => {
            return [...prevFiles, undefined]
        })
    }

    function relativeRender() {
        return (
            <div className="modal fade custom-modal text-center" id="manualTaskUploadModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog text-left modal-lg" role="document">
                    <div className="modal-content">
                        <ModalHeader title={"Add Documents"} />
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-8">
                                    <div style={{ marginTop: "10px" }}>
                                        {getAccessDropdown()}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div style={{ marginTop: "10px" }}>
                                        {getLockFileCheckbox()}
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-4">
                                    {getFiles()}
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ paddingLeft: '15px' }}>
                            <h6 className="italicFont"> Supported File are: .pdf, .txt, .doc, .docx, .xls, .xlsx, .csv, .jpg, .jpeg, .png</h6>
                            <h6 className="italicFont"> Each File should not exceed 10MB</h6>
                        </div>
                        <ModalFooter saveCallback={submitForm} saveInactive={inTransit}
                            buttonConfig={[{ buttonName: "+ File", callback: addFileCallback, inactive: inTransit }]} />
                    </div>
                </div>
            </div>
        )
    }

    return ReactDOM.createPortal(relativeRender(), document.getElementById('modal') as Element);
}

export default ModalManualTaskUpload;